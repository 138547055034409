import { ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  SelectProps, CSSObject, Theme, styled, List, Box, Backdrop, Skeleton,
  SxProps,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { CabComponentProps } from "../cabStyled";
import { CabNavItem, CabNavItemProps } from "./CabNavItem";
import colors from "../../../colors";
import { User } from "../../../store";
import { CabNavSettings } from "./CabNavSettings/CabNavSettings";
import { PassedComponent } from "../../../utils/types";
import { CabIcon } from "@CabComponents/CabIcon";
import { DndContext, useSensor, PointerSensor, closestCenter, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CabButton } from "..";
import { useMountEffect } from "../../../utils/hooks";
import { 
  IoCheckmarkCircleOutline, IoChevronBackCircleOutline, IoChevronForwardCircleOutline, IoCloseCircleOutline,
  IoCreateOutline,
} from "react-icons/io5";
import { IconType } from "react-icons";
import { useWindowSize } from "react-use";

const drawerWidth = 230;
const transitionDuration = 300;
const TEAM_GROUP_ID = 'TEAM_GROUP';

export interface SectionItem {
  title: string;
  locked?: boolean;
  hidden?: boolean;
  Icon?: IconType;
  iconOverride?: PassedComponent<object>;
  IconRight?: IconType;
  path?: string;
  onClick: () => void;
  onClickLocked?: () => void,
  onClickMobile?: () => void,
  childItems?: SectionItem[],
  id?: number;
  groupId?: string;
  newIcon?: boolean;
  elementId?: string;
}

export interface Section {
  items: SectionItem[];
}

type DrawerSectionProps = {
  section: Section;
  showMore: boolean;
  sectionItemActive: (sectionItem: SectionItem) => boolean;
  handleSetOpen? : (value: boolean) => void;
  scrollable?: boolean;
  sx?: SxProps<Theme>;
};

type DrawerLeaderSectionProps = {
  section: Section;
  showMore: boolean;
  sectionItemActive: (sectionItem: SectionItem) => boolean;
  handleSetOpen? : (value: boolean) => void;
  sortable?: boolean;
  sortableLeaders: SectionItem[];
  setSortableLeaders: React.Dispatch<React.SetStateAction<SectionItem[]>>
};

export interface CabNavBarProps extends CabComponentProps<SelectProps> {
  logo: string;
  collapsedLogo: string;
  mainSection: Section;
  sectionItemActive: (sectionItem: SectionItem) => boolean;
  parentOpen?: boolean
  onOpen?: (value: boolean) => void
  user: User | null | undefined;
  onChangePage: (pageUrl: string, external?: boolean) => void;
  onLogout: (global?: boolean) => void;
  versionNumber: string;
  leadersLoaded: boolean;
  variant: "permanent" | "temporary";
  onTemporaryDrawerClick: (value: unknown) => void;
  onOpenAddLeader?: () => void;
  onReorderLeaders: (leaderIds: number[]) => Promise<void>
}

export const CabNavBar = ({
  logo, collapsedLogo, mainSection: mSection, sectionItemActive, parentOpen, onOpen, onOpenAddLeader, user,
  onChangePage, onLogout, versionNumber, leadersLoaded, variant, onTemporaryDrawerClick, onReorderLeaders
}: CabNavBarProps): ReactElement => {
  const [localOpen, setLocalOpen] = useState(parentOpen || false);
  const [showMore, setShowMore] = useState(parentOpen || false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [sortLeaders, setSortLeaders] = useState(false);
  const [leaders, setLeaders] = useState<SectionItem[]>([]);
  const [settingsExpanded, setSettingsExpanded] = useState(false);

  const { height } = useWindowSize();
  const scrollable = height < 580;

  const leaderSection = useMemo(() => {
    const lSection = mSection.items.find(item => item.groupId === TEAM_GROUP_ID);
    if (!lSection) return { items: [] };

    const sec = { ...lSection, items: [...(lSection.childItems || [])] };

    if (!user?.features.DISABLE_USER_LEADER_CONTROL && !sortLeaders) {
      sec.items = [...sec.items, {
        title: "Edit Order",
        Icon: IoCreateOutline,
        onClick: () => setSortLeaders(true),
        locked: false,
        id: -2
      }];
    }

    return sec;
  }, [mSection.items, user?.features.DISABLE_USER_LEADER_CONTROL, sortLeaders]);

  const mainSection: Section = useMemo(() => {
    if (sortLeaders) {
      return {
        items: mSection.items.filter(item => item.groupId !== TEAM_GROUP_ID)
      };
    }

    return {
      items: mSection.items.map(item => 
        item.groupId === TEAM_GROUP_ID
          ? { ...item, ...leaderSection, childItems: leaderSection.items }
          : item
      )
    };
  }, [sortLeaders, mSection.items, leaderSection]);

  useEffect(() => {
    setLeaders(leaderSection.items.filter(item => item.id !== -1));
  }, [leaderSection.items]);

  const cancelEditOrder = useCallback(() => {
    setLeaders(leaderSection.items.filter(item => item.id !== -1));
    setSortLeaders(false);
  }, [leaderSection.items]);

  const handleSetOpen = useCallback((value: boolean): void => {
    if (onOpen) {
      onOpen(value);
    }
    setLocalOpen(value);
  }, [onOpen]);

  useEffect(() => {
    if (localOpen && !showMore) {
      setTimeout(() => {
        setShowMore(true);
      }, transitionDuration - 200);
    } else if (!localOpen && showMore) {
      setShowMore(false);
    }
    // we only want to run this effect when localOpen changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localOpen]);

  useEffect(() => {
    if (!localOpen) {
      if (sortLeaders) {
        cancelEditOrder();
      }
    }
  }, [cancelEditOrder, localOpen, sortLeaders]);

  const handleDrawerOpen = useCallback(() => {
    handleSetOpen(true);
  }, [handleSetOpen]);

  const handleDrawerClose = useCallback(() => {
    handleSetOpen(false);
  }, [handleSetOpen]);

  useEffect(() => {
    if (parentOpen) {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }
  }, [handleDrawerOpen, handleDrawerClose, parentOpen]);

  const handleSettingsToggle = (isExpanded: boolean) => {
    if (isExpanded) {
      handleDrawerOpen();
      setShowOverlay(true);
      setSettingsExpanded(true);
    } else {
      setShowOverlay(false);
      setSettingsExpanded(false);
    }
  };

  const saveLeaderOrder = () => {
    const leaderIds = leaders.map(leader => leader.id || -1);
    const filterIds = leaderIds.filter(id => id !== -1);
    onReorderLeaders(filterIds);
    setSortLeaders(false);
  };

  return (
    <Drawer
      variant={variant}
      anchor="left"
      open={localOpen}
      scrollable={scrollable}
      transitionDuration={transitionDuration}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' && variant === 'temporary') {
          handleDrawerClose();
        }
      }}
      onClick={() => settingsExpanded && handleSettingsToggle(false)}
    >
      <Box sx={{ direction: 'ltr', ...(!scrollable ? { height: '100%', paddingBottom: 20 } : {}) }}>
        <Backdrop open={showOverlay} sx={{ zIndex: 1, position: 'absolute' }} />
        <DrawerHeader
          open={showMore}
          sx={localOpen ? {
            padding: 1.75,
            paddingTop: 1.55,
            justifyContent: 'space-between'
          } : {
            backgroundColor: !showMore ? colors.chamomile500 : undefined
          }}
        >
          {showMore ? (
            <>
              <Box component='img'
                src={logo}
                fontSize={24}
                width={155}
                height='auto'
                paddingTop={0.5}
              />
              <DrawerTriggerIcon
                open={showMore}
                Icon={IoChevronBackCircleOutline}
                color='primary'
                alt={showMore ? 'collapse' : 'expand'}
                onClick={handleDrawerClose}
              />
            </>
          ) : (
            <Box
              sx={{ 
                position: 'relative',
                display: 'flex',
                '&:hover img': {
                  display: 'none'
                },
                '&:hover .hover-icon': {
                  display: 'block'
                }
              }}
            >
              <Box 
                component='img' 
                src={collapsedLogo} 
                fontSize={24} 
                width={46} 
                height='auto'
                onClick={handleDrawerOpen}
                marginTop={0}
                marginLeft={0.75}
              />
              <CabButton 
                buttonType='text'
                sx={{
                  display: 'none', 
                  padding: 1,
                  marginLeft: 0.5,
                  marginBottom: '2px',
                  '& .MuiButton-icon': { 
                    marginRight: 0, 
                    marginLeft: 0,
                    padding: 0
                  },
                  '& .MuiButton-icon svg': {
                    fontSize: '24px'
                  },
                  minWidth: 0
                }}
                className="hover-icon"
                onClick={handleDrawerOpen}
                icon={
                  <CabIcon
                    Icon={IoChevronForwardCircleOutline}
                    color='primary'
                    alt={'expand'}
                  />
                }
              />
            </Box>
          )}
        </DrawerHeader>
        <Box sx={{ height: '100%' }}>
          <DrawerSection
            key={"main"}
            section={mainSection}
            showMore={localOpen}
            sectionItemActive={sectionItemActive}
            handleSetOpen={handleSetOpen}
            scrollable={!scrollable}
            sx={sortLeaders ? {
              height: 'auto',
              overflowY: 'hidden',
            } : {}}
          />
          {sortLeaders && (
            <>
              <Box sx={{
                height: '100%',
                // overflowY: 'auto',
              }}>
                <Box display='flex' paddingLeft={2} paddingRight={2} paddingTop={1} paddingBottom={1} gap={1}>
                  <CabButton buttonType='tertiary' color='primary' onClick={cancelEditOrder}
                    icon={<CabIcon alt='Delete' Icon={IoCloseCircleOutline} />} 
                    size="small" sx={{width: '50%', height: '26px'}}>
                    Cancel
                  </CabButton>
                  <CabButton buttonType='primary' color='primary' onClick={saveLeaderOrder}
                    icon={<CabIcon alt='Save' Icon={IoCheckmarkCircleOutline}/>} 
                    size="small" sx={{width: '50%', height: '26px'}}>
                    Save
                  </CabButton>
                </Box>

                <OptionalBoxWrapper wrap={scrollable}>
                  <Scrollable open={localOpen} scrollable={scrollable}>
                    <Box sx={{ direction: 'ltr', height: 80 * leaders.length }}>
                      {leadersLoaded
                        ? <DrawerLeaderSection
                          key={"executives"}
                          section={leaderSection}
                          showMore={localOpen}
                          sectionItemActive={sectionItemActive}
                          sortable={sortLeaders}
                          sortableLeaders={leaders}
                          setSortableLeaders={setLeaders}
                        />
                        : <>
                          <LeaderSkeleton variant="rectangular" />
                          <LeaderSkeleton variant="rectangular" />
                          <LeaderSkeleton variant="rectangular" />
                        </>
                      }
                    </Box>
                  </Scrollable>
                </OptionalBoxWrapper>
              </Box>
            </>
          )}
        </Box>
      </Box>

      <CabNavSettings
        expanded={settingsExpanded}
        onToggle={handleSettingsToggle}
        isBarExpanded={showMore}
        user={user}
        onChangePage={(pageUrl: string, external?: boolean) => onTemporaryDrawerClick(
          onChangePage(pageUrl, external)
        )}
        onLogout={onLogout}
        versionNumber={versionNumber}
        sx={{ position: scrollable ? 'unset' : 'absolute', width: '100%', zIndex: 2, 
          direction: localOpen ? 'ltr' : 'unset' }} 
      />
    </Drawer>
  );
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: colors.systemWhite,
  paddingTop: 'env(safe-area-inset-top)',
  border: 0,
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(8.5),
  backgroundColor: colors.systemWhite,
  paddingTop: 'env(safe-area-inset-top)',
  border: 0,
  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
});

const DrawerHeader = styled(
  'div', { shouldForwardProp: (prop) => prop !== 'open', label: "DrawerHeader" }
)<{ open?: boolean }>(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: 3,
  paddingTop: 2,
  height: 64,
}));

const DrawerTriggerIcon = styled(
  CabIcon, { shouldForwardProp: (prop) => prop !== 'open', label: "DrawerTriggerIcon" }
)<{ open: boolean }>(({ open }) => ({
  ...(open ? {} : { transform: 'rotate(180deg)' }),
  fontSize: "24px",
  '&:hover': {
    cursor: 'pointer'
  }
}));

const LeaderSkeleton = styled(Skeleton, { label: "LeaderSkeleton" })(() => ({
  margin: 12,
  height: 32,
  borderRadius: 8
}));

const Scrollable = styled(
  Box, { shouldForwardProp: (prop: string) => 
    !['open', 'scrollable'].includes(prop), label: "Scrollable" }
)<{ open?: boolean, scrollable?: boolean }>(
  ({ theme, open, scrollable }) => ({
    direction: 'rtl',
    overflowY: scrollable ? 'unset' : 'auto',
    height: '100%',
    marginBottom: scrollable ? 8 : 97,
    '::-webkit-scrollbar': {
      width: open ? 8 : 4,
      height: 8,
      backgroundColor: 'none',
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.black500,
      borderRadius: open ? 4 : 2,
    }
  })
);


const OptionalBoxWrapper = ({ children, wrap }: { children: ReactNode, wrap: boolean }) => (
  wrap ? <Box>{children}</Box> : <>{children}</>
);

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'scrollable', label: "Drawer" }
)<{ scrollable?: boolean }>(
  ({ theme, open, variant, scrollable }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    direction: 'rtl',
    '& .MuiPaper-root': {
      overflowY: scrollable ? 'auto' : 'hidden',
      '::-webkit-scrollbar': {
        width: open ? 8 : 4,
        height: 8,
        backgroundColor: 'none',
      },
      '::-webkit-scrollbar-thumb': {
        background: colors.black500,
        borderRadius: open ? 4 : 2,
      }
    },
    boxSizing: 'border-box',
    ...(open && {
      ...(variant === 'permanent' && openedMixin(theme)),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...(variant === 'permanent' && closedMixin(theme)),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerSection = ({ 
  section, showMore, sectionItemActive, handleSetOpen, scrollable, sx
}: DrawerSectionProps): ReactElement => {
  const [uncollapseAll, setUncollapseAll] = useState(false);

  return (
    <List sx={{ 
      paddingTop: 1,
      height: scrollable ? '100%' : 'auto',
      overflowY: scrollable ? 'auto' : 'hidden',
      '::-webkit-scrollbar': {
        width: showMore ? 8 : 4,
        height: 8,
        backgroundColor: 'none',
      },
      '::-webkit-scrollbar-thumb': {
        background: colors.black500,
        borderRadius: showMore ? 4 : 2,
      },
      ...sx
    }}>
      {section.items.map((sectionItem) => {
        const childItems: CabNavItemProps[] = sectionItem.childItems?.map(((item: Partial<SectionItem>) => {
          const text = item.title;
          const key = item.path || item.title;
          return {
            ...item,
            open: showMore,
            text,
            key,
            active: false,
          } as CabNavItemProps;
        })) || [];

        return <CabNavItem
          open={showMore}
          key={sectionItem.path || sectionItem.title}
          text={sectionItem.title}
          active={sectionItemActive(sectionItem)}
          Icon={sectionItem.Icon}
          IconRight={sectionItem.IconRight}
          iconOverride={sectionItem.iconOverride}
          locked={sectionItem.locked}
          hidden={sectionItem.hidden}
          onClick={sectionItem.onClick}
          onClickLocked={sectionItem.onClickLocked}
          onClickMobile={sectionItem.onClickMobile}
          childItems={childItems}
          handleNavbarDrawer={handleSetOpen}
          newIcon={sectionItem.newIcon}
          elementId={sectionItem.elementId}
          uncollapseAll={uncollapseAll}
          setUncollapseAll={setUncollapseAll}
        />;
      })}
    </List>
  );
};

const DrawerLeaderSection = ({ 
  section, showMore, sectionItemActive, handleSetOpen, sortable, sortableLeaders, setSortableLeaders 
}: DrawerLeaderSectionProps): ReactElement => {
  
  const sensors = useSensor(PointerSensor);
  useMountEffect(() => {
    setSortableLeaders(section.items.filter(item => item.id !== -1));
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;

    if (over && active.id !== over.id) {
      setSortableLeaders((items) => {
        const oldIdx = items.findIndex( item => item.id === active.id);
        const newIdx = items.findIndex( item => item.id === over.id);

        return arrayMove(items, oldIdx, newIdx);
      });
    }
  };

  return (
    !sortable ? (
      <>
        <List>
          {section.items.map((sectionItem) => {
            const childItems: CabNavItemProps[] = sectionItem.childItems?.map(((item: Partial<SectionItem>) => {
              const text = item.title;
              const key = item.path || item.title;
              return {
                ...item,
                open: showMore,
                text,
                key,
                active: false,
              } as CabNavItemProps;
            })) || [];

            return <CabNavItem
              open={showMore}
              key={sectionItem.path || sectionItem.title}
              text={sectionItem.title}
              active={sectionItemActive(sectionItem)}
              Icon={sectionItem.Icon}
              IconRight={sectionItem.IconRight}
              iconOverride={sectionItem.iconOverride}
              locked={sectionItem.locked}
              hidden={sectionItem.hidden}
              onClick={sectionItem.onClick}
              onClickLocked={sectionItem.onClickLocked}
              onClickMobile={sectionItem.onClickMobile}
              childItems={childItems}
              handleNavbarDrawer={handleSetOpen}
            />;
          })}
        </List>
      </>
    ) : (
      <DndContext
        sensors={[sensors]}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={sortableLeaders.map(item => item.id || -1)}
          strategy={verticalListSortingStrategy}
        >
          <List>
            {sortableLeaders.map((sectionItem) => {
              const childItems: CabNavItemProps[] = sectionItem.childItems?.map(((item: Partial<SectionItem>) => {
                const text = item.title;
                const key = item.path || item.title;
                return {
                  ...item,
                  open: showMore,
                  text,
                  key,
                  active: false,
                } as CabNavItemProps;
              })) || [];

              return <CabNavItem
                open={showMore}
                key={sectionItem.path || sectionItem.title}
                text={sectionItem.title}
                active={sectionItemActive(sectionItem)}
                Icon={sectionItem.Icon}
                IconRight={sectionItem.IconRight}
                iconOverride={sectionItem.iconOverride}
                locked={sectionItem.locked}
                hidden={sectionItem.hidden}
                onClick={sectionItem.onClick}
                onClickLocked={sectionItem.onClickLocked}
                onClickMobile={sectionItem.onClickMobile}
                childItems={childItems}
                handleNavbarDrawer={handleSetOpen}
                sortId={sectionItem.id}
                sortable={sortable}
              />;
            })}
          </List>
        </SortableContext>
      </DndContext>
    )
  );
};
