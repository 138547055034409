import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkAction, UnknownAction } from '@reduxjs/toolkit';
import { ThunkDispatchType } from './types';
import { RootAction, RootState } from '.';


export const useWithDispatch = <Args extends unknown[], T>(
  fn: (...args: Args) => ThunkAction<T, RootState, null, RootAction>
) => {
// export const useWithDispatch = <T>(fn: ActionCreator<ThunkAction<T, RootState, null, RootAction>>) => {
// export const useWithDispatch = <T extends string, Payload>(fn: ActionCreatorWithPayload<Payload, T>) => {
  const dispatch = useDispatch<ThunkDispatchType>();

  return useCallback((...args: Args) => dispatch(fn(...args)), [dispatch, fn]);
  // return useCallback((args: Payload) => dispatch(fn(args)), [dispatch, fn]);
};

export const useHandler = () => {
  const dispatch = useDispatch();
  const handler = useCallback(
    <TArg extends unknown[], TAction extends UnknownAction>(actionCreator: (...args: TArg) => TAction) => 
      (...args: TArg) => dispatch(actionCreator(...args)),
    [dispatch]
  );
  return handler;
};
