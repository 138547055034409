import { IconButton, styled } from "@mui/material";
import { CabComponentProps } from "../cabStyled";
import { ReactElement } from "react";
import { IconType, IconBaseProps } from "react-icons";


export interface ICabIcon extends CabComponentProps<Record<string, never>> {
  Icon: IconType;
  size?: 'small'|'large'|number;
  slot?: 'end'|'start'
  alt?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  color?: string;
  disabled?: boolean;
}

export const CabIcon = ({
  Icon, size, alt, onClick, slot, sx, color, className, disabled
}: ICabIcon): ReactElement => {

  const title = alt || '';

  const props: IconBaseProps = {
    size: size === 'large' ? "32px" : size === 'small' ? "18px" : size,
    color,
    className,
    title
  };

  return (
    onClick 
      ? <StyledIconButton onClick={onClick} disabled={disabled} disableRipple title={`${title}-button`}>
        <StyledSpan title={title} slot={slot} sx={sx}>
          <Icon {...props} style={{pointerEvents: "none"}}/>
        </StyledSpan>
      </StyledIconButton>

      : <StyledSpan title={title} slot={slot} sx={sx}>
        <Icon {...props} style={{pointerEvents: "none"}}/>
      </StyledSpan>
  );
};


const StyledSpan = styled('span', { label: 'StyledSpan' })(() => ({
  display: 'flex',
  margin: 0
}));


const StyledIconButton = styled(IconButton, {label: 'StyledIconButton'})(() => ({
  padding: 0,
  margin: 0,
}));
