import { CustomFields } from '../store';
import { NOTE_FIELD_LABELS, ERROR_TYPE, ERROR_MESSAGE, emailRegex } from '../constants';

export type SetState<T> = React.Dispatch<React.SetStateAction<T>>;
export type SetObjectStateField<T> = (value: T) => void;

export const generateUniqueKey = (): string => '_' + Math.random().toString(36).substr(2, 9);

export interface MutableCustomField {
  label: string; 
  value: string; 
  isUnique: boolean;
  uniqueKey: string;
}

export type MutableCustom = (MutableCustomField)[];

//mutable custom field lets users create their own card fields
//-label is the user entered title for the field
//-value is the user entered value
//-isUnique will be true if label is unique and there are no errors
//-uniqueKey is used as a key for react when mapping
export const getNewMutableCustomField = (): MutableCustomField => (
  {
    label: '', 
    value: '', 
    isUnique: true, 
    uniqueKey: generateUniqueKey(),
  });

export const customToMutableCustom = (custom: CustomFields): MutableCustom => {
  const mutableCustom: MutableCustom = [];
  Object.keys(custom).forEach((field): void => {
    if (custom[field]) {
      mutableCustom[custom[field].order] = {
        label: field, 
        value: custom[field].value, 
        isUnique: true,
        uniqueKey: generateUniqueKey()};
    }
  }); 
  return mutableCustom.filter((field) => field);
};

export const isUniqueCustomLabel = (mutableCustom: MutableCustom, newLabel: string): boolean => {
  return !mutableCustom.some((field): boolean => field.label === newLabel);
};

export const mutableCustomToCustom = (mutableCustom: MutableCustom): [CustomFields, string[]] => {
  const custom: CustomFields = {};
  const errors: string[] = [];

  mutableCustom.forEach((field, index): void => {
    //if key already exists its a duplicate - so dont save changes
    if (field.label in custom) {
      if (!errors.includes(field.label)) {
        errors.push(field.label);
      }
    }
    //we only want to send the field, value, and order to the backend if label is not empty
    if (field.label) {
      custom[field.label] = {value: field.value, order: index};
    }
  });
  return [custom, errors];
};

export interface FieldErrors {
  [fieldKey: string]: {[K in keyof typeof ERROR_TYPE]: {message: string; valid: boolean; fieldLabel: string}};
}

export const addFieldError = (
  fieldErrors: FieldErrors, fieldKey: keyof typeof NOTE_FIELD_LABELS, errorType: keyof typeof ERROR_TYPE
): FieldErrors => {
  const error = fieldErrors[fieldKey] || {[errorType]: {message: '', valid: false}};
  error[errorType].message = '- ' + NOTE_FIELD_LABELS[fieldKey] + ' is ' + ERROR_MESSAGE[errorType] + '\n';
  error[errorType].valid = false;
  return {...fieldErrors, [fieldKey]: error};
};

export const removeFieldError = (
  fieldErrors: FieldErrors, fieldKey: string, errorType: keyof typeof ERROR_TYPE
): FieldErrors => {
  const newErrors = {...fieldErrors};
  if (fieldErrors[fieldKey]) {
    newErrors[fieldKey][errorType].valid = true;
  }
  return newErrors;
};

export const validateEmail = (email: string): boolean => {
  return emailRegex.test(email);
};

export type ParsedNameAndEmail = {
  name: string | null;
  email: string;
};

// This function parses names/emails from an import string. It handles many formats - for more details, see the story:
// https://app.shortcut.com/cabinet/story/12593/import-attendees-to-meeting-poll-using-copy-paste
export const parseEmailAddresses = (input: string): ParsedNameAndEmail[] => {
  const entries: ParsedNameAndEmail[] = [];

  // Normalize input
  const normalizedInput = input.trim().replace(/\r?\n/g, '\n').replace(/\s+\n/g, '\n');

  // Check explicitly for CSV format: "Name, email, etc."
  const lines = normalizedInput.split(/\r?\n/).map(line => line.trim()).filter(Boolean);
  const isNameEmailCSVFormat = lines.every(line => {
    const splitLine = line.split(',');
    return splitLine.length >= 2 && !splitLine[0].includes('@') && splitLine[1].includes('@');
  });

  if (isNameEmailCSVFormat) {
    for (const line of lines) {
      const [name, email] = line.split(',').map(part => part.trim().replace(/["']/g, ''));
      entries.push({ name: name || null, email: email.toLowerCase() });
    }
    return entries;
  }

  // Existing parsing logic (other formats)
  const segments = normalizedInput.split(/[\n,;]+/);

  const emailWithNameRegex = /^([^<]+?)\s*<([\w.%+-]+@[\w.-]+\.[A-Za-z]{2,})>$/;
  const emailOnlyRegex = /^[\w.%+-]+@[\w.-]+\.[A-Za-z]{2,}$/;
  const csvLineRegex = /^([^<]+?)\s*<([\w.%+-]+@[\w.-]+\.[A-Za-z]{2,})>,?/;

  for (const segment of segments) {
    const trimmedSegment = segment.trim();

    let match = trimmedSegment.match(emailWithNameRegex);
    if (match) {
      const name = match[1].trim().replace(/["']/g, '');
      const email = match[2].toLowerCase();
      entries.push({ name, email });
      continue;
    }

    if (emailOnlyRegex.test(trimmedSegment)) {
      const email = trimmedSegment.toLowerCase();
      entries.push({ name: null, email });
      continue;
    }

    match = trimmedSegment.match(csvLineRegex);
    if (match) {
      const name = match[1].trim().replace(/["']/g, '');
      const email = match[2].toLowerCase();
      entries.push({ name, email });
      continue;
    }

    const parts = trimmedSegment.split(/\t+/);
    if (parts.length > 0) {
      const emailMatch = parts[0].match(emailWithNameRegex);
      if (emailMatch) {
        const name = emailMatch[1].trim().replace(/["']/g, '');
        const email = emailMatch[2].toLowerCase();
        entries.push({ name, email });
      }
    }
  }

  return entries;
};