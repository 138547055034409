import { ReactElement, ReactNode } from "react";
import { 
  List, 
  ListProps, 
  ListItem, 
  ListItemButton, 
  ListItemIcon, 
  ListItemText,
  IconButton, 
  ListItemAvatar,
  Avatar,
  styled,
} from '@mui/material';
import { CabComponentProps } from "../cabStyled";
import colors from "../../../colors";
import { CabTooltip } from "../CabTooltip";
import { CabIcon } from "../CabIcon";
import { IoCloseCircleOutline } from "react-icons/io5";


export interface CabListProps extends CabComponentProps<ListProps> {
  items: {
    key?: string | number;
    value: string | number;
    label: ReactNode;
    tooltip?: string;
    icon?: ReactElement;
    avatar?: ReactElement;
    editable: boolean;
    onDeleteClick?: () => void;
    onClick?: () => void;
    disabled?: boolean;
    customEditIcon?: ReactElement;
  }[];
}

export const CabList = ({
  overrides, items,
}: CabListProps): ReactElement => {
  return (
    <ListStyled dense {...overrides} >
      {items.map(item => {
        return <CabTooltip key={item.key || item.value} placement="left" title={item.tooltip || ''}>
          <ListItem disablePadding disabled={item.disabled} onClick={item.onClick}
            secondaryAction={ item.editable && (
              <IconButton edge='end' aria-label='remove' disableRipple onClick={
                (e) => {
                  e.stopPropagation();
                  if (item.onDeleteClick) {
                    item.onDeleteClick();
                  }
                }}>
                {item.customEditIcon ? (
                  <>{item.customEditIcon}</>
                ) : (
                  <CabIcon Icon={IoCloseCircleOutline} color={colors.lavendar700} alt='Remove' />
                )}
              </IconButton>
            )}
          >
            <ListItemButton>
              { item.icon && (
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
              )}
              { item.avatar && (
                <ListItemAvatar>
                  <Avatar>{item.avatar}</Avatar>
                </ListItemAvatar>
              )}
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        </CabTooltip>;
      })}
    </ListStyled>
  );
};

const ListStyled = styled(List, {
  label: 'ListStyled',
})<CabComponentProps<ListProps>>(({ theme }) => ({
  ...(theme.palette.mode === 'dark' ? {
    backgroundColor: colors.white200,
    '& :not(:last-child)': {
      '&.MuiListItem-root': {
        borderBottom: '1px solid ' + colors.black900,
      },
    },
    '& .MuiListItemText-primary': {
      color: colors.white900,
      fontSize: theme.typography.body2.fontSize,
      paddingTop: 1
    },
    '& .MuiIconButton-root': {
      color: colors.white700
    },
    '& .MuiListItemIcon-root': {
      color: colors.white600,
      fontSize: theme.typography.body2.fontSize,
      minWidth: 24,
      marginRight: 12,
    },
  } : {
    // backgroundColor: colors.black100,
    '& :not(:last-child)': {
      '&.MuiListItem-root': {
        borderBottom: '1px solid ' + colors.black200,
        '& .MuiListItemIcon-root': {
          marginBottom: 4
        },
        '& .MuiAvatar-root': {
          marginBottom: 2,
        }
      },
    },
    '& .MuiListItemText-primary': {
      color: colors.black900,
      fontSize: theme.typography.body2.fontSize,
    },
    '& .MuiListItemIcon-root': {
      color: colors.black500,
      fontSize: theme.typography.body2.fontSize,
      minWidth: 24,
      marginRight: 12,
    },
  }),
  width: '100%',
  border: '1px solid ' + colors.black200,
  borderRadius: 8,
  paddingTop: 1,
  paddingBottom: 1,
  '& .MuiAvatar-root': {
    width: 24,
    height: 24,
    fontSize: theme.typography.body1.fontSize,
  },
  '& .MuiListItemAvatar-root': {
    minWidth: 24,
    marginRight: 12,
  },
}));
