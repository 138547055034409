import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import mixpanel from "mixpanel-browser";
import { isPlatform } from "./platformUtils";
import { getCurrentScope as getSentryScope } from "@sentry/react";

const isWeb = isPlatform('mobileweb') || isPlatform('desktop');

const initLogrocket = (): void => {
  const protectedUrlTest = /^.*(login|refresh_tokens|register|challenge|remote-calendar-events|remote-calendars).*$/;
  const scrubFields = (
    data: {[key: string | number]: string | null | undefined}, fieldList: Array<string | number>
  ) => {
    if (!data) {
      return data;
    }
    fieldList.forEach(field => {
      if (data[field]) {
        data[field] = '';
      }
    });
    return data;
  };  
  const logRocketAppId = import.meta.env.VITE_LOGROCKET_APPID;

  /* TODO: Logrocket currently cannot support loading files from mobile/non-public domains so we don't waste sessions.
      We would need to host static files on a public domain and set baseHref to that domain for mobile.
      https://docs.logrocket.com/reference/dom */
  if (isWeb && logRocketAppId) {
    LogRocket.init(logRocketAppId, {
      dom: {
        textSanitizer: true,
        inputSanitizer: true,
      },
      browser: {
        urlSanitizer: url => {
          let sanitizedUrl = url;

          // redact the path following /integrations/ from the URL
          //sanitizedUrl = sanitizedUrl.replace(/\/integrations\/([^\/]*)/i, '/integrations/**redacted**');

          // Sanitize OAuth codes
          sanitizedUrl = sanitizedUrl.replace(/code=([^&]*)/, 'code=**redacted**');
          return sanitizedUrl;
        }
      },
      network: {
        requestSanitizer: request => {
          request.headers = scrubFields(
            request.headers, 
            ['Authorization']
          );
          
          if (request.url && request.url.toLowerCase().match(protectedUrlTest)) {
            request.body = undefined;
          } else if (request.url && request.url.toLowerCase().includes("cognito-idp.us-east-1.amazonaws.com")) {
            request.body = undefined;
          }
          return request;
        },
        responseSanitizer: response => {
          // The current typeings for response are wrong. `url` is a valid property confirmed through testing.
          type LRResponse = typeof response & {url: string};
          
          if ((response as LRResponse).url && (response as LRResponse).url.toLowerCase().match(protectedUrlTest)) {
            response.body = undefined;
          }
          return response;
        }
      }
    });
    setupLogRocketReact(LogRocket);

    LogRocket.getSessionURL(function (sessionURL) {
      if (import.meta.env.VITE_MIXPANEL_TOKEN) {
        mixpanel.track("LogRocket", {sessionURL});
      }

      const sentryScope = getSentryScope();
      sentryScope.setExtra("sessionURL", sessionURL);
    });
  }
};

if (process.env.NODE_ENV === 'production') {
  initLogrocket();
}