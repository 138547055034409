import { GridFilterModel } from "@mui/x-data-grid-pro";
import { ActionType } from '../actionTypes';
import { MeetingType } from '../schedule/types';

import { MeetingLogColumnHide, MeetingLogColumnSort } from './types';

export const setCabNavbarExpanded = (expanded: boolean) => {
  return { type: ActionType.SET_CAB_NAVBAR_EXPANDED, navbarExpanded: expanded };
};

export const setMeetingLogFilter = (meetingType: MeetingType, filter: GridFilterModel) => {
  filter.items = filter.items.map((item) => {
    return item.value instanceof Date ? {...item, value: item.value.toISOString()} : item;
  });
  return { type: ActionType.SET_MEETING_LOG_FILTER, meetingType, filter };
};

export const setMeetingLogColumnSort = (meetingType: MeetingType, sort: MeetingLogColumnSort) => {
  return { type: ActionType.SET_MEETING_LOG_COLUMN_SORT, meetingType, sort };
};

export const setMeetingLogColumnHide = (meetingType: MeetingType, hide: MeetingLogColumnHide) => {
  return { type: ActionType.SET_MEETING_LOG_COLUMN_HIDE, meetingType, hide };
};
