import { DateTime } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { cabCaptureMessage } from "./logging";
import { ReactElement, ReactNode, useMemo } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { useSelector } from "react-redux";
import { RootState, WeekStartDay } from "../store";

export const createCabLuxonAdapter = (weekStartDay?: WeekStartDay | null) => {
  class CabLuxonAdapter extends AdapterLuxon {
    // The one in AdapterLuxon is private and so we can't re-use it.
    private setLocaleToValue2 = (value: DateTime) => {
      const expectedLocale = this.getCurrentLocaleCode();
      if (expectedLocale === value.locale) {
        return value;
      }
  
      return value.setLocale(expectedLocale);
    };
  
    startOfWeek = (value: DateTime) => {
      return value.startOf(
        "week",
        { useLocaleWeeks: false }
      ).minus({ days: weekStartDay === WeekStartDay.SUNDAY ? 1 : 0 });
    };
  
    endOfWeek = (value: DateTime) => {
      return value.endOf(
        "week",
        { useLocaleWeeks: false }
      ).minus({ days: weekStartDay === WeekStartDay.SUNDAY ? 1 : 0 });
    };
  
    getWeekArray = (value: DateTime) => {
      const cleanValue = this.setLocaleToValue2(value);
      const firstDay = this.startOfWeek(this.startOfMonth(cleanValue));
      const lastDay = this.endOfWeek(this.endOfMonth(cleanValue));
  
      let { days } = lastDay.diff(firstDay, "days").toObject();
  
      // this should never happen but somehow did
      if (days != null && days < 0) {
        days = 0;
        cabCaptureMessage(
          `Negative days was calculated in getWeekArray for value ${value.toISO()}.
          Value Locale: ${value.locale}, 
          Clean Value Locale: ${cleanValue.locale}, 
          firstDay: ${firstDay.toISO()},
          lastDay: ${lastDay.toISO()}`
        );
      }
  
      const weeks: DateTime[][] = [];
      new Array<number>(Math.round(days!))
        .fill(0)
        .map((_, i) => i)
        .map((day) => firstDay.plus({ days: day }))
        .forEach((v, i) => {
          if (i === 0 || (i % 7 === 0 && i > 6)) {
            weeks.push([v]);
            return;
          }
  
          weeks[weeks.length - 1].push(v);
        });
  
      return weeks;
    };
  
    getWeekNumber = (value: DateTime) => {
      return value.localWeekNumber ?? value.weekNumber;
    };
  };

  return CabLuxonAdapter;
};

interface CustomLocalizationProviderProps {
  children: ReactElement[] | ReactElement | ReactNode; 
}

export const CustomLocalizationProvider = ({ 
  children 
}: CustomLocalizationProviderProps): ReactElement => {
  // Create a custom adapter class that uses this redux state.
  const weekStartDay = useSelector((state: RootState) => state.schedule.schedulingPrefs.user_prefs?.week_start_day);
  const CustomAdapter = useMemo(() => createCabLuxonAdapter(weekStartDay), [weekStartDay]);
  
  return <LocalizationProvider dateAdapter={CustomAdapter}>
    {children}
  </LocalizationProvider>;
};