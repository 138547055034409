import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { Views as RBCViews, View as RBCView } from 'react-big-calendar';
import { Calendar, RootState } from '..';
import { ScheduleUIState } from './types';
import { 
  getLocalTimeZone, getTimeZone, getWeekRange, NY_TZ, TimeZone 
} from '../../utils/scheduleUtils';


// GUIDELINES:
// - API Slice (e.g. schedule) should primarily be responsible for API interactions (ideally using RTKQ and generated)
// - UI Slice (e.g. scheduleUI) should be responsible for UI state and user interactions (but shouldn't need thunks)
// - Effects should be used to trigger API calls from UI Slice actions and compose more complex logic

// - reducers payloads should be simple and serializable (primitives and simple objects).  If you need actions that take
// non-primitive objects, you can create an additional simple action that basically wraps the reducer action.


// TODO: newMeeting state should be moved here from schedule


type CalendarWithAccess = Omit<Calendar, 'calendar_access_id'> & { calendar_access_id: number };

const { start: currentWeekStart, end: currentWeekEnd } = getWeekRange(DateTime.now());

export const initialState: ScheduleUIState = {
  currentMeetingId: null,
  selectedLeaderIds: [],
  selectedAdditionalCalendarAccessIds: [],
  hiddenCalendarIds: [],
  timezoneName: DateTime.local().zoneName || NY_TZ.name,
  secondaryTimezoneNames: [],
  startTimestampMs: currentWeekStart.toMillis(),
  endTimestampMs: currentWeekEnd.toMillis(),
  showAllRecurringSlots: false,
  showCanceledDeclinedMeetings: true,
  showPendingSlots: true,
  condensePendingSlots: false,
  showAllDayEvents: true,
  showMultiLeaderColumns: false,
  calendarView: RBCViews.WORK_WEEK,
};


export const scheduleUISlice = createSlice({
  name: 'scheduleUI',
  initialState,
  reducers: {
    currentMeetingSet: (state, action: PayloadAction<number | null>) => {
      state.currentMeetingId = action.payload;
    },
    leaderSelectionUpdated: (state, action: PayloadAction<{ leaderId: number, selected: boolean }[]>) => {
      const selectedLeaderSet = new Set(state.selectedLeaderIds);
      action.payload.forEach(({ leaderId, selected }) => 
        selected ? selectedLeaderSet.add(leaderId) : selectedLeaderSet.delete(leaderId)
      );
      state.selectedLeaderIds = Array.from(selectedLeaderSet);
    },
    additionalCalendarSelectionUpdated: (
      state, action: PayloadAction<{ calendarAccessId: number, selected: boolean }[]>
    ) => {
      const selectedCalendarSet = new Set(state.selectedAdditionalCalendarAccessIds);
      action.payload.forEach(({ calendarAccessId, selected }) =>
        selected ? selectedCalendarSet.add(calendarAccessId) : selectedCalendarSet.delete(calendarAccessId)
      );
      state.selectedAdditionalCalendarAccessIds = Array.from(selectedCalendarSet);
    },
    hiddenCalendarIdsUpdated: (state, action: PayloadAction<{ calendarId: number, hidden: boolean }[]>) => {
      const hiddenCalendarSet = new Set(state.hiddenCalendarIds);
      action.payload.forEach(({ calendarId, hidden }) =>
        hidden ? hiddenCalendarSet.add(calendarId) : hiddenCalendarSet.delete(calendarId)
      );
      state.hiddenCalendarIds = Array.from(hiddenCalendarSet);
    },
    calendarTimezoneUpdated: (state, action: PayloadAction<string>) => {
      state.timezoneName = action.payload;
    },
    secondaryTimezonesUpdated: (state, action: PayloadAction<string[]>) => {
      state.secondaryTimezoneNames = action.payload;
    },
    timezonesInitialized: (state, action: PayloadAction<{primary: string, secondary: string[]}>) => {
      state.timezoneName = action.payload.primary;
      state.secondaryTimezoneNames = action.payload.secondary;
    },
    dateRangeUpdated: (state, action: PayloadAction<{ startTimestampMs: number, endTimestampMs: number }>) => {
      state.startTimestampMs = action.payload.startTimestampMs;
      state.endTimestampMs = action.payload.endTimestampMs;
    },
    showAllRecurringSlotsUpdated: (state, action: PayloadAction<boolean>) => {
      state.showAllRecurringSlots = action.payload;
    },
    showCanceledDeclinedMeetingsUpdated: (state, action: PayloadAction<boolean>) => {
      state.showCanceledDeclinedMeetings = action.payload;
    },
    showPendingSlotsUpdated: (state, action: PayloadAction<boolean>) => {
      state.showPendingSlots = action.payload;
    },
    condensePendingSlotsUpdated: (state, action: PayloadAction<boolean>) => {
      state.condensePendingSlots = action.payload;
    },
    showAllDayEventsUpdated: (state, action: PayloadAction<boolean>) => {
      state.showAllDayEvents = action.payload;
    },
    showMultiLeaderColumnsUpdated: (state, action: PayloadAction<boolean>) => {
      state.showMultiLeaderColumns = action.payload;
    },
    calendarViewUpdated: (state, action: PayloadAction<RBCView>) => {
      state.calendarView = action.payload;
    },
  },
});

export const {
  leaderSelectionUpdated, additionalCalendarSelectionUpdated, hiddenCalendarIdsUpdated, currentMeetingSet,
  calendarTimezoneUpdated, secondaryTimezonesUpdated, dateRangeUpdated, showAllRecurringSlotsUpdated,
  showCanceledDeclinedMeetingsUpdated, showPendingSlotsUpdated, condensePendingSlotsUpdated,
  showAllDayEventsUpdated, showMultiLeaderColumnsUpdated, calendarViewUpdated, timezonesInitialized,
} = scheduleUISlice.actions;

export default scheduleUISlice.reducer;


export const updateDateRange = (start: DateTime, end: DateTime) => 
  dateRangeUpdated({
    startTimestampMs: start.toMillis(),
    endTimestampMs: end.toMillis(),
  });

export const updateCalendarTimezone = (timezone: TimeZone) => 
  calendarTimezoneUpdated(timezone.name);

export const updateSecondaryTimezones = (timezones: TimeZone[]) => 
  secondaryTimezonesUpdated(timezones.map(tz => tz.name));

export type ScheduleUIAction = ReturnType<typeof scheduleUISlice.actions[keyof typeof scheduleUISlice.actions]>;


export const selectCurrentMeeting = createSelector([
  (state: RootState) => state.scheduleUI.currentMeetingId,
  (state: RootState) => state.schedule.meetings,
], (currentMeetingId, meetings) => {
  return currentMeetingId ? meetings[currentMeetingId] : null;
});

export const selectCurrentOrNewMeeting = createSelector([
  (state: RootState) => state.schedule.newMeeting,
  selectCurrentMeeting,
], (newMeeting, currentMeeting) => {
  return currentMeeting || newMeeting;
});

export const selectCalendarAccessToCalendarMap = createSelector([
  (state: RootState) => state.schedule.calendars,
], (calendars) => {
  return Object.fromEntries(calendars
    .filter((calendar): calendar is CalendarWithAccess => !!calendar.calendar_access_id)
    .map(calendar => [calendar.calendar_access_id, calendar]));
});

export const selectCalendarMap = createSelector([
  (state: RootState) => state.schedule.calendars,
], (calendars) => {
  return Object.fromEntries(calendars.map(calendar => [calendar.id, calendar]));
});

export const selectSelectedLeaderCalendars = createSelector([
  (state: RootState) => state.leaders.leaders,
  (state: RootState) => state.schedule.calendars,
  (state: RootState) => state.scheduleUI.selectedLeaderIds,
], (leaders, calendars, selectedLeaderIds) => {
  const calendarIds = leaders
    .filter(l => selectedLeaderIds.includes(l.id))
    .flatMap(l => Object.keys(l.leader_calendars || {}));

  return calendars.filter(c => calendarIds.includes(c.id.toString()));
});

export const selectSelectedAdditionalCalendars = createSelector([
  selectCalendarAccessToCalendarMap,
  (state: RootState) => state.scheduleUI.selectedAdditionalCalendarAccessIds,
], (calendarAccessToCalendarMap, selectedAdditionalCalendarAccessIds) => {
  return selectedAdditionalCalendarAccessIds
    .map(accessId => calendarAccessToCalendarMap[accessId])
    .filter(c => !!c);
});

export const selectMeetingById = createSelector([
  (state: RootState) => state.schedule.meetings,
  (state: RootState, meetingId?: number) => meetingId,
], (meetings, meetingId) => (
  meetingId != null && meetingId in meetings ? meetings[meetingId] : null
));

export const selectNewMeeting = (state: RootState) => state.schedule.newMeeting;

export const selectMeetingViewableAdditionalCalendars = createSelector([
  selectCalendarAccessToCalendarMap,
  selectMeetingById,
  selectNewMeeting,
  (state: RootState, meetingId: number) => meetingId,
], (calendarAccessToCalendarMap, meeting, newMeeting, meetingId) => {
  const targetMeeting = meetingId === -1 ? newMeeting : meeting;
  if (!targetMeeting) return [];

  return Object.values(targetMeeting.participants || {})
    .filter(p => p.calendar_access != null && p.view_calendar && !!calendarAccessToCalendarMap[p.calendar_access])
    .map(p => calendarAccessToCalendarMap[p.calendar_access as number]);
});

export const selectAllHiddenCalendarIds = createSelector([
  selectCalendarAccessToCalendarMap,
  selectCurrentOrNewMeeting,
  (state: RootState) => state.scheduleUI.hiddenCalendarIds,
], (calendarAccessToCalendarMap, currentOrNewMeeting, hiddenCalendarIds) => {
  // TODO: We should probably just have a hiddenCalendarIds state and then use effects to sync
  // hiddenCalendarsIds with meeting participants/leaders
  const hiddenParticipantCalendarIds = Object.values(currentOrNewMeeting?.participants || {})
    .filter(p => p.calendar_access && !p.view_calendar)
    .map(p => calendarAccessToCalendarMap[p.calendar_access as number]?.id)
    .filter(id => !!id);

  const hiddenLeaderCalendarIds = (currentOrNewMeeting?.leader_info || [])
    .filter(l => l.view_calendar != null && !l.view_calendar)
    .flatMap(l => Object.values(l.leader_calendars || {}).map(lc => lc.calendar));

  return Array.from(new Set([...hiddenCalendarIds, ...hiddenParticipantCalendarIds, ...hiddenLeaderCalendarIds]));
});

export const selectActiveCalendars = createSelector([
  selectSelectedLeaderCalendars,
  selectSelectedAdditionalCalendars,
], (selectedLeaderCalendars, selectedAdditionalCalendars) => {
  return uniqBy([...selectedLeaderCalendars, ...selectedAdditionalCalendars], ({ id }) => id);
});

export const selectVisibleCalendars = createSelector([
  selectActiveCalendars,
  (state: RootState) => state.scheduleUI.hiddenCalendarIds,
], (activeCalendars, hiddenCalendarIds) => {
  return activeCalendars.filter(c => !hiddenCalendarIds.includes(c.id));
});

export const selectTimezone = createSelector([
  (state: RootState) => state.scheduleUI.timezoneName,
], (timezoneName) => {
  const tz = getTimeZone(timezoneName) || NY_TZ;
  return tz;
});

export const selectSecondaryTimezones = createSelector([
  (state: RootState) => state.scheduleUI.secondaryTimezoneNames,
], (secondaryTimezoneNames) => {
  return secondaryTimezoneNames.map(tzName => getTimeZone(tzName) || NY_TZ);
});

export const selectDateRange = createSelector([
  (state: RootState) => state.scheduleUI.startTimestampMs,
  (state: RootState) => state.scheduleUI.endTimestampMs,
], (startTimestampMs, endTimestampMs) => {
  return {
    start: DateTime.fromMillis(startTimestampMs),
    end: DateTime.fromMillis(endTimestampMs),
  };
});

export const selectMiddleOfInterval = createSelector([
  selectDateRange,
  selectTimezone
], (dateRange, timezone) => {
  const { start, end } = dateRange;

  const midpoint = start.plus({ milliseconds: end.diff(start).milliseconds / 2 });
  return midpoint.setZone(timezone.name);
});

export const selectActiveTimezones = createSelector([
  selectTimezone,
  selectSecondaryTimezones,
  selectCurrentMeeting,
], (timezone, secondaryTimezones, currentMeeting) => {
  if (currentMeeting?.calendar_tz) {
    return [
      getTimeZone(currentMeeting.calendar_tz) || getLocalTimeZone() || NY_TZ,
      ...(currentMeeting?.secondary_tz || [])
        .filter(tz => tz != null)
        .map(tz => getTimeZone(tz))
        .filter(tz => !!tz),
    ];
  }

  return [timezone, ...secondaryTimezones];
});
