export enum ActionType {
  //auth
  NO_TOKEN = 'NO_TOKEN',
  USER_LOADING = 'USER_LOADING',
  USER_LOADED = 'USER_LOADED',
  UPDATED_USER_PROFILE = 'UPDATED_USER_PROFILE',
  LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL',
  PASSWORD_CHANGE_REQUIRED = 'PASSWORD_CHANGE_REQUIRED',
  FORGOT_PASSWORD_STARTED = 'FORGOT_PASSWORD_STARTED',
  PASSWORD_CHANGE_SUCCESFUL = 'PASSWORD_CHANGE_SUCCESFUL',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  LOGIN_FAILED = 'LOGIN_FAILED',
  LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL',
  PASSWORD_CHANGE_CANCELLED = 'PASSWORD_CHANGE_CANCELLED',
  REGISTRATION_INCOMPLETE = 'REGISTRATION_INCOMPLETE',
  UPDATED_USER_PREFS = 'UPDATED_USER_PREFS',
  SAVED_OAUTH_GRANT = 'SAVED_OAUTH_GRANT',
  UPDATE_ONBOARDING_COMPLETED = 'UPDATE_ONBOARDING_COMPLETED',
  UPDATE_REFERENCE_TYPE = 'UPDATE_REFERENCE_TYPE',
  UPDATED_USER_ACTIVE_LICENSE = 'UPDATED_USER_ACTIVE_LICENSE',
  ACCEPTED_TOS = 'ACCEPTED_TOS',
  SET_AUTH_REDIRECT = 'SET_AUTH_REDIRECT',
  ONBOARDING_INITIALIZED = 'ONBOARDING_INITIALIZED',

  //staticData
  FETCHED_AIRLINES = 'FETCHED_AIRLINES',
  FETCHED_COUNTRIES = 'FETCHED_COUNTRIES',
  FETCHED_INDUSTRIES = 'FETCHED_INDUSTRIES',
  FETCHING_AIRLINES = 'FETCHING_AIRLINES',
  FETCHING_COUNTRIES = 'FETCHING_COUNTRIES',
  FETCHING_INDUSTRIES = 'FETCHING_INDUSTRIES',
  FETCHED_PROMO_TASK_COLLECTION = 'FETCHED_PROMO_TASK_COLLECTION',
  FETCHED_PROMO_MEETING_POLLS = 'FETCHED_PROMO_MEETING_POLLS',

  //organization
  FETCHED_ORG_USER_AUTOCOMPLETE_OPTIONS = 'FETCHED_ORG_USER_AUTOCOMPLETE_OPTIONS',
  FETCHED_SHARED_LEADER_GRANTS = 'FETCHED_SHARED_LEADER_GRANTS',
  REVOKED_SHARED_LEADER_GRANT = 'REVOKED_SHARED_LEADER_GRANT',
  SHARED_LEADER = 'SHARED_LEADER',
  FETCHED_SUBSCRIPTION_DETAILS = 'FETCHED_SUBSCRIPTION_DETAILS',
  CHANGED_SUBSCRIPTION = 'CHANGED_SUBSCRIPTION',
  SET_ORGANIZATION = 'SET_ORGANIZATION',

  //leaders
  FETCHED_LEADERS = 'FETCHED_LEADERS',
  SELECTED_LEADER = 'SELECTED_LEADER',
  CREATED_LEADER = 'CREATED_LEADER',
  DELETED_LEADER = 'DELETED_LEADER',
  UPDATED_LEADER = 'UPDATED_LEADER',
  REORDER_LEADERS = 'REORDER_LEADERS',
  UPDATED_LEADER_CALENDARS = 'UPDATED_LEADER_CALENDARS',
  FETCHED_ORG_LEADERS = 'FETCHED_ORG_LEADERS',
  FETCHED_ORG_SHARED_LEADER_GRANTS = 'FETCHED_ORG_SHARED_LEADER_GRANTS',
  FETCHED_ORG_LEADER_CATEGORIES = 'FETCHED_ORG_LEADER_CATEGORIES',
  CREATED_ADMIN_LEADER = "CREATED_ADMIN_LEADER",
  UPDATED_ADMIN_LEADER = "UPDATED_ADMIN_LEADER",

  //airlines
  FETCHED_AIRLINE_NOTES = 'FETCHED_AIRLINE_NOTES',
  UPDATED_AIRLINE_NOTE = 'UPDATED_AIRLINE_NOTE',
  DELETED_AIRLINE_NOTE = 'DELETED_AIRLINE_NOTE',
  CREATED_AIRLINE_NOTE = 'CREATED_AIRLINE_NOTE',

  //personal
  FETCHED_PERSONAL_NOTES = 'FETCHED_PERSONAL_NOTES',
  UPDATED_PERSONAL_NOTE = 'UPDATED_PERSONAL_NOTE',
  DELETED_PERSONAL_NOTE = 'DELETED_PERSONAL_NOTE',
  CREATED_PERSONAL_NOTE = 'CREATED_PERSONAL_NOTE',

  //hotels
  FETCHED_HOTELS = 'FETCHED_HOTELS',
  FETCHING_HOTELS = 'FETCHING_HOTELS',
  FETCHED_HOTEL_NOTES = 'FETCHED_HOTEL_NOTES',
  CREATED_HOTEL_NOTE = 'CREATED_HOTEL_NOTE',
  UPDATED_HOTEL_NOTE = 'UPDATED_HOTEL_NOTE',
  DELETED_HOTEL_NOTE = 'DELETED_HOTEL_NOTE',

  //profileNotes
  FETCHING_PROFILE_CATEGORIES = 'FETCHING_PROFILE_CATEGORIES',
  FETCHED_PROFILE_CATEGORIES = 'FETCHED_PROFILE_CATEGORIES',
  UPDATED_PROFILE_CATEGORY = 'UPDATED_PROFILE_CATEGORY',
  DELETED_PROFILE_CATEGORY = 'DELETED_PROFILE_CATEGORY',
  CREATED_PROFILE_CATEGORY = 'CREATED_PROFILE_CATEGORY',
  FETCHED_PROFILE_NOTES = 'FETCHED_PROFILE_NOTES',
  UPDATED_PROFILE_NOTE = 'UPDATED_PROFILE_NOTE',
  DELETED_PROFILE_NOTE = 'DELETED_PROFILE_NOTE',
  CREATED_PROFILE_NOTE = 'CREATED_PROFILE_NOTE',

  //global message
  SEND_MESSAGE = 'SEND_MESSAGE',
  REMOVE_MESSAGE = 'REMOVE_MESSAGE',

  //globalModal
  OPEN_GLOBAL_MODAL = 'OPEN_GLOBAL_MODAL',
  CLOSE_GLOBAL_MODAL = 'CLOSE_GLOBAL_MODAL',

  //schedule
  SAVING_MEETING_SLOTS = 'SAVING_MEETING_SLOTS',
  EDITING_MEETING_SLOTS = 'EDITING_MEETING_SLOTS',
  SAVED_MEETING_SLOTS = 'SAVED_MEETING_SLOTS',
  SAVED_MEETING_QUESTIONS = 'SAVED_MEETING_QUESTION',
  SAVED_MEETING = 'SAVED_MEETING',
  FETCHED_CALENDAR_ASSOCIATIONS = 'FETCHED_CALENDAR_ASSOCIATIONS',
  CREATED_CALENDAR = 'CREATED_CALENDAR',
  UPDATED_CALENDAR = 'UPDATED_CALENDAR',
  FETCHING_AVAILABLE_CALENDARS = 'FETCHING_AVAILABLE_CALENDARS',
  FETCHED_AVAILABLE_CALENDARS = 'FETCHED_AVAILABLE_CALENDARS',
  CREATED_AVAILABLE_CALENDAR = 'CREATED_AVAILABLE_CALENDAR',
  MEETINGS_LOADED = 'MEETINGS_LOADED',
  FETCHED_MEETINGS = 'FETCHED_MEETINGS',
  FETCHED_MEETING = 'FETCHED_MEETING',
  UPDATED_MEETING = 'UPDATED_MEETING',
  DELETED_MEETING = 'DELETED_MEETING',
  DELETED_MEETINGS = 'DELETED_MEETINGS',
  UPDATED_NEW_MEETING = 'UPDATED_NEW_MEETING',
  DELETED_NEW_MEETING = 'DELETED_NEW_MEETING',
  FETCHED_MEETING_SLOTS = 'FETCHED_MEETING_SLOTS',
  FETCHED_SLOTS_FOR_MEETING = 'FETCHED_SLOTS_FOR_MEETING',
  FETCHED_MEETING_EXTERNAL = 'FETCHED_MEETING_EXTERNAL',
  SET_POLL_SELECTIONS = 'SET_POLL_SELECTIONS',
  DELETED_POLL_SELECTIONS = 'DELETED_POLL_SELECTIONS',
  FETCHED_POLL_SELECTIONS = 'FETCHED_POLL_SELECTIONS',
  FETCHED_POLL_RESULTS = 'FETCHED_POLL_RESULTS',
  FETCHED_PARTICIPANT_AUTOCOMPLETE_OPTIONS = 'FETCHED_PARTICIPANT_AUTOCOMPLETE_OPTIONS',
  UPDATED_MEETING_SLOT = 'UPDATED_MEETING_SLOT',
  DELETED_MEETING_SLOT = 'DELETED_MEETING_SLOT',
  DELETED_MEETING_SLOTS = 'DELETED_MEETING_SLOTS',
  UPDATED_MEETING_QUESTION = 'UPDATED_MEETING_QUESTION',
  DELETED_MEETING_QUESTIONS = 'DELETED_MEETING_QUESTIONS',
  FETCHED_ZOOM_SETTINGS = 'FETCHED_ZOOM_SETTINGS',
  UPDATED_ZOOM_SETTINGS = 'UPDATED_ZOOM_SETTINGS',
  FETCHED_SCHEDULING_PREFS = 'FETCHED_SCHEDULING_PREFS',
  UPDATED_SCHEDULING_PREFS = 'UPDATED_SCHEDULING_PREFS',
  LOGGED_OUT_OAUTH = 'LOGGED_OUT_OAUTH',
  SET_SCHEDULE_ERRORS = 'SET_SCHEDULE_ERRORS',
  SET_MEETING_SCHEDULE_ERRORS = 'SET_MEETING_SCHEDULE_ERRORS',
  SET_MEETING_QUESTION_ANSWERS = 'SET_MEETING_QUESTION_ANSWERS',
  SET_EXTERNAL_PARTICIPANT = 'SET_EXTERNAL_PARTICIPANT',
  SET_MEETING_PARTICIPANT = 'SET_MEETING_PARTICIPANT',
  UPDATED_MEETING_PARTICIPANT = 'UPDATED_MEETING_PARTICIPANT',
  DELETED_MEETING_PARTICIPANT = 'DELETED_MEETING_PARTICIPANT',
  UPDATED_MEETING_LEADER = 'UPDATED_MEETING_LEADER',
  SET_MEETING_ROOMS = 'FETCH_MEETING_ROOMS',
  SET_MEETING_AUDIT_LOGS = 'FETCH_MEETING_AUDIT_LOGS',
  SET_RECENTLY_SCHEDULED = 'FETCH_RECENTLY_SCHEDULED',
  FETCHED_PRESET_LOCATIONS = 'FETCHED_PRESET_LOCATIONS',
  UPDATED_PRESET_LOCATION = 'UPDATED_PRESET_LOCATION',
  DELETED_PRESET_LOCATION = 'DELETED_PRESET_LOCATION',
  FETCHED_REMOTE_CALENDAR_EVENTS = 'FETCHED_REMOTE_CALENDAR_EVENTS',

  //organization
  UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION',
  UPDATE_ORGANIZATION_LICENSES = 'UPDATE_ORGANIZATION_LICENSES',
  SET_PARTIAL_ORGANIZATION = 'SET_PARTIAL_ORGANIZATION',

  // cabUI
  SET_CAB_NAVBAR_EXPANDED = 'SET_CAB_NAVBAR_EXPANDED',
  SET_FETCH_ERROR = 'SET_FETCH_ERROR',
  SET_PREVIOUS_ROUTE = 'SET_PREVIOUS_ROUTE',
  SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE',
  SET_NEXT_ROUTE = 'SET_NEXT_ROUTE',
  SET_MEETING_LOG_FILTER = 'SET_MEETING_LOG_FILTER',
  SET_MEETING_LOG_COLUMN_SORT = 'SET_MEETING_LOG_COLUMN_SORT',
  SET_MEETING_LOG_COLUMN_HIDE = 'SET_MEETING_LOG_COLUMN_HIDE',

  // eventAnalytics
  UPDATE_ANALYTICS_EVENTS = 'UPDATE_ANALYTICS_EVENTS',
  UPDATE_EXECUTIVE_CONTACTS_RECONCILIATION = 'UPDATE_EXECUTIVE_CONTACTS_RECONCILIATION',
  SET_ANALYTICS_EVENTS = 'SET_ANALYTICS_EVENTS',
  SET_EXECUTIVE_CONTACTS_RECONCILIATION = 'SET_EXECUTIVE_CONTACTS_RECONCILIATION',
  UPDATE_CONSOLIDATED_LOCATION = 'UPDATE_CONSOLIDATED_LOCATION',
  UPDATE_EVENT_CATEGORIES = 'UPDATE_EVENT_CATEGORIES',
  UPDATE_EVENT_TYPES = 'UPDATE_EVENT_TYPES',
  UPDATE_EXECUTIVE_CONTACT_CATEGORIES = 'UPDATE_EXECUTIVE_CONTACT_CATEGORIES',
  SET_ANALYTICS_EVENTS_ORDER = 'SET_ANALYTICS_EVENT_ORDER',
  UPDATE_ANALYTICS_EVENTS_ORDER = 'UPDATE_ANALYTICS_EVENT_ORDER',
  SET_EVENT_ANALYSIS_RESPONSE = 'SET_EVENT_ANALYSIS_RESPONSE',
  UPDATE_EXECUTIVE_CONTACTS_ANALYSIS = 'UPDATE_EXECUTIVE_CONTACTS_ANALYSIS',
  DELETED_EVENT_CATEGORY = 'DELETED_EVENT_CATEGORY',
  DELETED_EVENT_TYPE = 'DELETED_EVENT_TYPE',
  DELETED_EXECUTIVE_CONTACT_CATEGORIES = 'DELETED_EXECUTIVE_CONTACT_CATEGORIES',
  UPDATE_RECURRENCE_TYPE = 'UPDATE_RECURRENCE_TYPE',
  SET_EXECUTIVE_CONTACT_TYPES = 'SET_EXECUTIVE_CONTACT_TYPES',
  UPDATE_ANALYTICS_META = 'UPDATE_ANALYTICS_META',
  SET_ANALYSIS_INCLUSIONS = 'SET_ANALYSIS_INCLUSIONS',
  SET_ANALYTICS_COLORS = "SET_ANALYTICS_COLORS",
  SET_FETCH_ACTION = "SET_FETCH_ACTION",
}