import { Box, ButtonGroup, ButtonGroupProps, Popover, PopoverProps, SxProps, Theme } from "@mui/material";
import { ReactElement, useState, MouseEvent, useEffect } from "react";

export type CabCollapseMenuProps = {
  target?: ReactElement | string
  passedAnchorEl?: Element | null
  passedAnchorOrigin?: PopoverProps["anchorOrigin"]
  passedTransformOrigin?: PopoverProps["transformOrigin"]
  onClose?: () => void
  setIsOpen?: (open: boolean) => void;
  buttons: ReactElement
  popOverTitle: string
  sx?: SxProps<Theme> | undefined
  popOverSx?: SxProps<Theme> | undefined
  buttonGroupSx?: SxProps<Theme> | undefined
  popOverAnchorOrigin?: PopoverProps["anchorOrigin"]
  buttonGroupVariant?: ButtonGroupProps["variant"]
  buttonGroupOrientation?: ButtonGroupProps["orientation"]
  buttonGroupColor?: ButtonGroupProps["color"]
  buttonGroupId?: ButtonGroupProps["id"]
  disabled?: boolean;
};

const popOverAnchorOriginDefault: PopoverProps["anchorOrigin"] = {
  vertical: 'bottom',
  horizontal: 'left',
};

const buttonGroupVariantDefault = "contained";
const buttonGroupOrientationDefault = "vertical";

export const CabCollapseMenu = (
  {
    target, buttons, popOverTitle, sx, popOverSx, passedAnchorOrigin, popOverAnchorOrigin = popOverAnchorOriginDefault,
    buttonGroupVariant = buttonGroupVariantDefault, buttonGroupOrientation = buttonGroupOrientationDefault,
    buttonGroupColor, buttonGroupSx, passedAnchorEl, onClose, passedTransformOrigin, setIsOpen, buttonGroupId,
    disabled = false
  }: CabCollapseMenuProps
): React.ReactElement => {

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(passedAnchorEl || anchorEl) && !disabled;
  const id = open ? 'simple-popover' : undefined;

  useEffect( () => {
    if (setIsOpen) {
      setIsOpen(open);
    }
  }, [open, setIsOpen]);

  return (
    <Box sx={sx}>
      <Box 
        sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }} 
        id={id} 
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        {target}
      </Box>
      <Popover
        title={popOverTitle}
        sx={popOverSx}
        anchorOrigin={passedAnchorOrigin || popOverAnchorOrigin}
        open={open}
        transformOrigin={passedTransformOrigin}
        anchorEl={passedAnchorEl || anchorEl}
        onClose={onClose || handleClose}
      >
        <ButtonGroup
          variant={buttonGroupVariant}
          color={buttonGroupColor}
          orientation={buttonGroupOrientation}
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
          sx={buttonGroupSx}
          id={buttonGroupId}
        >
          {buttons}
        </ButtonGroup>
      </Popover>
    </Box>
  );
};

export default CabCollapseMenu;