import { Box, Chip, ChipProps, darken, lighten, styled } from "@mui/material";
import { isEqual } from "lodash-es";
import { ReactElement, memo } from "react";
// NOTE Importing Cabtooltip using a relative import ".." breaks Storybook 
import { CabTooltip } from "../CabTooltip";
import colors from "../../../colors";
import { CabComponentProps } from "../cabStyled";
import { getContrastTextColor } from "../../../utils/colorUtils";


export interface CabToggleChipProps extends CabComponentProps<ChipProps>{
  themeColor?: ChipProps['color'];
  chipColor?: string;
  translateColor?: boolean;
  disabled?: ChipProps['disabled'];
  onClick?: ChipProps['onClick'];
  onDelete?: ChipProps['onDelete'];
  onMouseDown?: ChipProps['onMouseDown'],
  label?: ChipProps['label'] | React.ReactElement;
  icon?: ChipProps['icon'];
  title?: ChipProps['title'];
  clickable?: ChipProps['clickable'];
  selected?: boolean;
  renderForExport?: boolean;
}

export const CabToggleChip = ({
  chipColor, translateColor, themeColor, sx, className, disabled, clickable, 
  onClick, onDelete, overrides, selected, label, icon, title, onMouseDown, renderForExport
}: CabToggleChipProps): ReactElement => {
  if (renderForExport) {
    let color = colors.white900;
    if (chipColor) {
      color = getContrastTextColor(chipColor);
    }
    return (
      <Box
        sx={{
          color,
          backgroundColor: chipColor,
          borderRadius: 2,
          boxSizing: 'border-box',
          height: 24,
          width: "auto",
          padding: '0px 9px 0px 9px',
          fontSize: 12
        }}
      >
        {label}
      </Box>    
    );
  }

  const toggleChipProps: ChipProps = {
    disabled,
    label,
    sx,
    clickable,
    className,
    onClick,
    onMouseDown,
    onDelete,
    icon,
  };

  toggleChipProps.variant = selected ? 'filled' : 'outlined';

  if (themeColor) {
    toggleChipProps.color = themeColor;
  }

  let component = (
    <ToggleChipStyled 
      {...toggleChipProps}
      chipColor={chipColor}
      translateColor={translateColor}
      {...overrides}
    />
  );

  if (title) {
    component = (
      <CabTooltip
        title={title}
        placement="bottom"
        wrapWithSpan
      >
        {component}
      </CabTooltip>
    );
  }

  return component;
};

const propsEqual = (prev:CabToggleChipProps, next:CabToggleChipProps) => {
  return isEqual(prev, next);
};

const ToggleChipStyled = memo(styled(Chip, { 
  shouldForwardProp: (prop: string) => 
    !['chipColor', 'translateColor'].includes(prop), label: "ToggleChipStyled" }
)<{ chipColor?: string; translateColor?: boolean }>
(({ variant, chipColor, translateColor }) => {
  let color = colors.white900;
  if (chipColor) {
    color = getContrastTextColor(chipColor);
  }

  return ({
    borderRadius: 8,
    boxSizing: 'border-box',
    height: 24,
    padding: '0px 9px 0px 9px',
    border: variant === 'filled'
      ? '1px solid transparent'
      : '',
    '& .MuiChip-label': {
      padding: 0,
    },
    '& .MuiChip-deleteIcon': {
      margin: 0,
      marginLeft: 2,
      marginRight: -4
    },
    '& .MuiChip-icon': {
      margin: 0,
      marginLeft: -4,
      marginRight: 2
    },
    ...(chipColor && {
      ...(translateColor ? {
        ...(variant === 'filled' ? {
          color: color,
          backgroundColor: chipColor,
          '&:hover': {
            backgroundColor: lighten(chipColor, 0.4),
            color: darken(chipColor, 0.6)
          }
        } : {
          color: chipColor,
          borderColor: chipColor,
        })
      } : {
        ...(variant === 'filled' ? {
          color: color,
          backgroundColor: chipColor,
        } : {

        })
      })
    })
  });
}), propsEqual);