import { createContext, ReactNode, useContext } from "react";
import { USER_ROLE } from "./constants";
import { Category, getUserRoleLocalizedString } from "./utils/userTypeStringLocalization";


const CabinetContext = createContext({ role: USER_ROLE.ASSISTANT });

interface Props { 
  children: ReactNode;
  userRole?: USER_ROLE;
}

export const CabinetContextProvider = ({ children, userRole }: Props) => {
  return (
    <CabinetContext.Provider value={{ role: userRole || USER_ROLE.ASSISTANT }}>
      {children}
    </CabinetContext.Provider>
  );
};

export const useCabinetText = <T extends Category>(categories: T[]) => {
  const { role } = useContext(CabinetContext);
  const res = categories.map(category => getUserRoleLocalizedString(category, role));
  return res;
};
