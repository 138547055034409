import { API_URLS } from '../../apiUrls';
import { ActionType } from '../actionTypes';
import { ThunkResult, ThunkDispatchType, FetchReturn } from '../types';
import { makeHeaders, fetchData } from '../../utils/apiUtils';
import { ProfileCategory, RootState, SharedLeaderGrant } from '..';
import { 
  CategoryLeaderPermissions, Leader, LeaderPermission, OrganizationLeader, StandardLeaderPermissions
} from './types';
import clone from 'lodash/clone';
import { sendMessage } from '../globalMessages/actions';
import api from '../../api';
import { sendPermissionDeniedMessage } from '../../utils/permissionUtils';
import { cabCaptureException } from '../../utils/logging';

export const fetchLeaders = (): ThunkResult<Promise<Record<string, unknown>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<Record<string, unknown>> => {
    const headers = await makeHeaders(true);

    return fetchData(API_URLS.LEADERS, { headers, }).then((res: FetchReturn): Record<string, unknown> => {
      if (res.status === 200) {
        dispatch({ type: ActionType.FETCHED_LEADERS, leaders: res.data });
        return res.data;
      } else {
        return {};
      }
    });
  };

interface UploadedLeaderPic {pic_url: string};

const uploadLeaderPic = async (
  leaderId: number, leaderPic: File | null, admin: boolean = false
): Promise<UploadedLeaderPic | null> => {
  if (!leaderPic) {
    // If there is no image and the name is null, that means the user didn't change the image.
    // If there is no image but the name is not null (empty string), that means the user deleted the image.
    return null;
  }

  const headers = await makeHeaders(true);
  if (headers) {
    delete headers["Content-Type"];
  }

  const formData = new FormData();
  formData.set("pic_url", leaderPic);

  const apiUrlFunc = admin ? API_URLS.ADMIN_LEADER_UPLOAD_PROFILE_PIC : API_URLS.LEADER_UPLOAD_PROFILE_PIC;

  const uploadLeaderPicRes = await fetchData<UploadedLeaderPic>(
    apiUrlFunc(leaderId), { headers, method: "POST", body: formData }
  );
  if (uploadLeaderPicRes.status === 200) {
    return uploadLeaderPicRes.data;
  } else {
    throw new Error('Could not upload user profile image');
  }
};

const createLeaderInfo = async (
  leader: Leader, idx?: number
): Promise<FetchReturn<Leader>> => {
  const newLeader = {
    first_name: leader.first_name,
    last_name: leader.last_name,
    email: leader.email,
    primary_assistant: leader.primary_assistant !== -1 ? leader.primary_assistant : undefined,
    company: leader.company,
    industry: leader.industry,
    role: leader.role,
    country: leader.country,
    postal_code: leader.postal_code,
    phone_number: leader.phone_number,
    leader_calendars: Object.values(leader.leader_calendars || {}),
    idx: idx !== undefined && idx > -1 ? idx : -1
  };

  const headers = await makeHeaders(true);
  const body = JSON.stringify(newLeader);

  return fetchData(API_URLS.LEADERS, { headers, method: 'POST', body });
};

const createAdminLeaderInfo = async (
  leader: Leader, idx?: number
): Promise<FetchReturn<OrganizationLeader>> => {
  const newLeader = {
    first_name: leader.first_name,
    last_name: leader.last_name,
    email: leader.email,
    primary_assistant: leader.primary_assistant !== -1 ? leader.primary_assistant : undefined,
    company: leader.company,
    industry: leader.industry,
    role: leader.role,
    country: leader.country,
    postal_code: leader.postal_code,
    phone_number: leader.phone_number,
    leader_calendars: Object.values(leader.leader_calendars || {}),
    idx: idx !== undefined && idx > -1 ? idx : -1
  };

  const headers = await makeHeaders(true);
  const body = JSON.stringify(newLeader);

  return fetchData(API_URLS.LEADERS + "admin-create/", { headers, method: 'POST', body });
};

const updateLeaderInfo = async (
  leader: Partial<Leader> & { id: Leader['id'] }
): Promise<FetchReturn<Leader>> => {
  const headers = await makeHeaders(true);
  const updatedLeader = clone(leader);
  let body = JSON.stringify(updatedLeader);
  if (updatedLeader.leader_calendars) {
    const leaderWithCalendars = {
      ...updatedLeader,
      leader_calendars: Object.values(updatedLeader.leader_calendars)
    };
    body = JSON.stringify(leaderWithCalendars);
  }

  return fetchData(API_URLS.LEADERS + leader.id + '/', { headers, method: 'PATCH', body });
};

const updateAdminLeaderInfo = async (
  leader: Partial<Leader> & { id: OrganizationLeader['id'] }
): Promise<FetchReturn<OrganizationLeader>> => {
  const headers = await makeHeaders(true);
  const updatedLeader = clone(leader);
  const body = JSON.stringify(updatedLeader);

  return fetchData(API_URLS.LEADERS + leader.id + '/admin-patch/', { headers, method: 'PATCH', body });
};

export const createLeader = (
  leader: Leader, leaderPic: File | null, idx?: number
): ThunkResult<Promise<Leader | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const res = await createLeaderInfo(leader, idx);
    if (res.status === 201) {
      let leaderPicUrl = res.data.pic_url;
      const leaderData = res.data;
      if (leaderPic) {
        const leaderPicUrlRes = await uploadLeaderPic(leaderData.id, leaderPic);
        leaderPicUrl = leaderPicUrlRes ? leaderPicUrlRes.pic_url : leaderPicUrl;
        leaderData.pic_url = leaderPicUrl;
      }
      dispatch({ type: ActionType.CREATED_LEADER, leader: leaderData });
      return leaderData;
    } else {
      cabCaptureException(new Error(`Couldn't create leader: ${JSON.stringify(res)}`));

      dispatch(sendMessage({
        timeout: 2000,
        message: 'Could not create leader, please try again.',
        autoDismiss: true,
        header: '',
        position: { horizontal: 'center', vertical: 'bottom' },
        active: true,
        severity: "error",
      }));

    }
  };

export const createAdminLeader = (
  leader: Leader, leaderPic: File | null, idx?: number
): ThunkResult<Promise<OrganizationLeader | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const res = await createAdminLeaderInfo(leader, idx);
    if (res.status === 201) {
      const leaderData = res.data;
      let leaderPicUrl = null;
      if (leaderPic) {
        const leaderPicUrlRes = await uploadLeaderPic(leaderData.id, leaderPic);
        leaderPicUrl = leaderPicUrlRes ? leaderPicUrlRes.pic_url : null;
        leaderData.pic_url = leaderPicUrl;
      }
      dispatch({ type: ActionType.CREATED_ADMIN_LEADER, orgLeader: leaderData });
      return leaderData;
    } else {
      cabCaptureException(new Error(`Couldn't create leader: ${JSON.stringify(res)}`));

      dispatch(sendMessage({
        timeout: 2000,
        message: 'Could not create leader, please try again.',
        autoDismiss: true,
        header: '',
        position: { horizontal: 'center', vertical: 'bottom' },
        active: true,
        severity: "error",
      }));

    }
  };

export const updateLeader = (
  leader: Partial<Leader> & { id: Leader['id'] }, leaderPic: File | null, removeLeaderPic?: boolean
): ThunkResult<Promise<Leader | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    let leaderUpdateInfo = {...leader};
  
    if (removeLeaderPic) {
      leaderUpdateInfo.pic_url = null;
    } else {
      await uploadLeaderPic(leader.id, leaderPic);
      const { pic_url, ...leaderWithoutPic} = leader;
      leaderUpdateInfo = leaderWithoutPic;
    }

    const leaderUpdateRes = await updateLeaderInfo(leaderUpdateInfo);

    if (leaderUpdateRes.status === 200) {
      dispatch({ type: ActionType.UPDATED_LEADER, leader: leaderUpdateRes.data });
      return leaderUpdateRes.data;
    } else {
      throw Error();
    }
  };

export const updateAdminLeader = (
  leader: Partial<Leader> & { id: OrganizationLeader['id'] }, leaderPic: File | null, removeLeaderPic?: boolean
): ThunkResult<Promise<OrganizationLeader | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    let leaderUpdateInfo = leader;
  
    if (removeLeaderPic) {
      leaderUpdateInfo.pic_url = null;
    } else {
      await uploadLeaderPic(leader.id, leaderPic, true);
      const { pic_url, ...leaderWithoutPic} = leader;
      leaderUpdateInfo = leaderWithoutPic;
    }

    const leaderUpdateRes = await updateAdminLeaderInfo(leaderUpdateInfo);
    if (leaderUpdateRes.status === 200) {
      dispatch({ type: ActionType.UPDATED_ADMIN_LEADER, orgLeader: leaderUpdateRes.data });
      return leaderUpdateRes.data;
    } else {
      throw Error();
    }

  };

export const deleteLeader = (leaderId: number): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    return fetchData(API_URLS.LEADERS + leaderId + '/', { headers, method: 'DELETE' })
      .then((res: FetchReturn): void => {
        if (res.status === 204) {
          dispatch({ type: ActionType.DELETED_LEADER, leaderId: leaderId });
        }
      });
  };

export const deleteAdminLeader = (leaderId: number): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    return fetchData(API_URLS.LEADERS + leaderId + '/admin-delete/', { headers, method: 'DELETE' })
      .then((res: FetchReturn): void => {
        if (res.status === 204) {
          dispatch({ type: ActionType.DELETED_LEADER, leaderId: leaderId });
        } else if (res.status === 400) {
          cabCaptureException(new Error('Couldn\'t delete leader'));
    
          dispatch(sendMessage({
            timeout: 4000,
            message: 'Not authenticated to delete this leader.',
            autoDismiss: true,
            header: '',
            position: { horizontal: 'center', vertical: 'bottom' },
            active: true,
            severity: "error",
          }));
        } else {
          cabCaptureException(new Error('Couldn\'t delete leader'));
    
          dispatch(sendMessage({
            timeout: 2000,
            message: 'Could not delete leader, please try again.',
            autoDismiss: true,
            header: '',
            position: { horizontal: 'center', vertical: 'bottom' },
            active: true,
            severity: "error",
          }));
        }
      });
  };

export const reorderLeaders = (leaderIds: number[]): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    const body = JSON.stringify(leaderIds);
    return fetchData(API_URLS.BATCH_REORDER_LEADERS, { headers, method: 'POST', body })
      .then((res: FetchReturn): void => {
        if (res.status === 200) {
          dispatch({ type: ActionType.REORDER_LEADERS, leaderOrders: res.data });
        }
      }).catch(err => {
        return err;
      });
  };

export const fetchOrganizationLeader = (): ThunkResult<Promise<FetchReturn<{ data: OrganizationLeader[] }>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<FetchReturn<{
    data: OrganizationLeader[]
  }>> => {
    const res = await api.fetchOrganizationLeaders();
    if (res.status === 200) {
      dispatch({ type: ActionType.FETCHED_ORG_LEADERS, orgLeaders: res.data.data });
    }
    return res;
  };

export const fetchOrganizationSharedLeaderGrants = (): ThunkResult<Promise<FetchReturn<{
  data: SharedLeaderGrant[]
}>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<FetchReturn<{
    data: SharedLeaderGrant[]
  }>> => {
    const res = await api.fetchOrganizationSharedLeaderGrants();
    if (res.status === 200) {
      const grants: { [id: number]: SharedLeaderGrant[] } = {};
      res.data.data.forEach(grant => {
        if (grant.leader in grants) {
          grants[grant.leader].push(grant);
        } else {
          grants[grant.leader] = [grant];
        }
      });
      dispatch({
        type: ActionType.FETCHED_ORG_SHARED_LEADER_GRANTS,
        grants
      });
    }
    return res;
  };

export const fetchOrganizationLeaderProfileCategories = (): ThunkResult<Promise<FetchReturn<{
  data: { [key: number]: ProfileCategory }
}>>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<FetchReturn<{
    data: { [key: number]: ProfileCategory }
  }>> => {
    const res = await api.fetchOrganizationLeaderProfileCategory();
    if (res.status === 200) {
      const categories: { [id: number]: ProfileCategory[] } = {};
      Object.values(res.data.data).forEach(category => {
        if (category.leader in categories) {
          categories[category.leader].push(category);
        } else {
          categories[category.leader] = [category];
        }
      });
      dispatch({
        type: ActionType.FETCHED_ORG_LEADER_CATEGORIES,
        categories
      });
    }
    return res;
  };

export const fetchSharedLeaderGrants = (): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {

    const headers = await makeHeaders(true);

    return fetchData<unknown>(API_URLS.SHARED_LEADERS, { headers, method: 'GET' })
      .then((res): void => {
        if (res.status === 200) {
          dispatch({ type: ActionType.FETCHED_SHARED_LEADER_GRANTS, grants: res.data });
        } else {
          sendPermissionDeniedMessage(dispatch, res);
        }
      });
  };

export const shareLeaderProfile = (
  collaboratorIds: number[], leaderId: number, permissions: StandardLeaderPermissions,
  category_permissions: CategoryLeaderPermissions, scheduling_permissions: LeaderPermission,
  startDate: string, endDate: string | null, forAssistant: boolean, isAdminUser?: boolean
): ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);

    const grant = {
      collaboratorIds,
      permissions,
      category_permissions,
      scheduling_permissions,
      startDate,
      endDate,
      isAdmin: forAssistant,
    };

    const body = JSON.stringify(grant);

    if (isAdminUser) {
      return fetchData(API_URLS.LEADERS + leaderId + '/admin-share-leader/', { headers, method: 'POST', body })
        .then((res: FetchReturn): void => {
          if (res.status === 200) {
            dispatch({ type: ActionType.SHARED_LEADER, grants: res.data, leaderId });
          }
        });
    }

    return fetchData(API_URLS.LEADERS + leaderId + '/share/', { headers, method: 'POST', body })
      .then((res: FetchReturn): void => {
        if (res.status === 200) {
          dispatch({ type: ActionType.SHARED_LEADER, grants: res.data, leaderId });
        }
      });
  };

export const transferAdminLeader = (
  leader_id: number, share_with_user: boolean, to_user_id: number, from_user_id?: number | null
): ThunkResult<Promise<OrganizationLeader | undefined>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState) => {
    const headers = await makeHeaders(true);

    const transfer = {
      leader_id,
      share_with_user,
      to_user_id,
      from_user_id,
    };

    const body = JSON.stringify(transfer);

    return fetchData(API_URLS.LEADERS + leader_id + '/admin-transfer-leader/', { headers, method: 'POST', body })
      .then((res: FetchReturn): OrganizationLeader | undefined => {
        if (res.status === 200) {
          dispatch({ type: ActionType.UPDATED_ADMIN_LEADER, orgLeader: res.data });
          return res.data;
        } else {
          Promise.reject();
        }
      });
  };

export const revokeSharedLeaderProfile = (grantId: number, leaderId: number, isAdminUser?: boolean): 
ThunkResult<Promise<void>> =>
  async (dispatch: ThunkDispatchType, getState: () => RootState): Promise<void> => {
    const headers = await makeHeaders(true);
    if (isAdminUser) {
      return fetchData(API_URLS.SHARED_LEADERS + grantId + '/admin-revoke/', { headers, method: 'PATCH', })
        .then((res: FetchReturn): void => {
          dispatch({ type: ActionType.REVOKED_SHARED_LEADER_GRANT, grantId, leaderId });
        });
    }
    return fetchData(API_URLS.SHARED_LEADERS + grantId + '/revoke/', { headers, method: 'PATCH', })
      .then((res: FetchReturn): void => {
        dispatch({ type: ActionType.REVOKED_SHARED_LEADER_GRANT, grantId, leaderId });
      });
  };