import { FetchReturn, ThunkDispatchType } from '../store';
import { ActionType } from '../store/actionTypes';


export type PermissionError =  {detail: string, required_features: string[]} | string;

export const sendPermissionDeniedMessage = (
  dispatch: ThunkDispatchType, res: FetchReturn<unknown, PermissionError>
) => {
  if (res.status === 403 && typeof res.data.detail !== "string") {
    dispatch({
      type: ActionType.SET_FETCH_ERROR,
      error: {
        status_code: res.status,
        message: res.data.detail.detail + ` Missing Features: ${
          res.data.detail.required_features
        }`
      }
    });
  }
};
