import * as authActions from './auth/actions';
import * as staticActions from './staticData/actions';
import * as organizationActions from './organization/actions';
import * as leaderActions from './leaders/actions';
import * as airlineActions from './airlines/actions';
import * as personalActions from './personal/actions';
import * as globalMessageActions from './globalMessages/actions';
import * as hotelActions from './hotels/actions';
import * as profileNoteActions from './profileNotes/actions';
import * as scheduleActions from './schedule/actions';
import * as globalModalActions from './globalModal/actions';
import * as cabUIActions from './cabUI/actions';
import { AuthAction } from './auth/types';
import { StaticDataAction } from './staticData/types';
import { OrganizationAction } from './organization/types';
import { LeaderAction, Leader } from './leaders/types';
import { AirlinesAction, AirlineNote } from './airlines/types';
import { PersonalAction, PersonalNote } from './personal/types';
import { HotelsAction, HotelNote } from './hotels/types';
import { ProfileNoteAction, ProfileNote } from './profileNotes/types';
import { GlobalMessageAction } from './globalMessages/types';
import { ScheduleAction } from './schedule/types';
import { GlobalModalAction } from './globalModal/types';
import { CabUIAction } from './cabUI/types';
import { EventAnalyticsAction } from "./eventAnalytics/types";
import { CRMAction } from './crm/types';
import { fabricateStore } from './store';
import { ScheduleUIAction } from './scheduleUI';

export { default, fabricateStore } from './store';

export const actions = {
  auth: authActions,
  staticData: staticActions,
  organization: organizationActions,
  leaders: leaderActions,
  airlines: airlineActions,
  personal: personalActions,
  globalMessage: globalMessageActions,
  hotels: hotelActions,
  profileNotes: profileNoteActions,
  schedule: scheduleActions,
  globalModal: globalModalActions,
  cabUI: cabUIActions
};

export * from './types';
export * from './auth/types';
export * from './organization/types';
export * from './staticData/types';
export * from './leaders/types';
export * from './airlines/types';
export * from './personal/types';
export * from './globalMessages/types';
export * from './hotels/types';
export * from './profileNotes/types';
export * from './schedule/types';
export * from './crm/types';
export * from './eventAnalytics/types';
export * from './globalModal/types';

export type AnyNote = AirlineNote | PersonalNote | Leader | HotelNote | ProfileNote;
export type AllNotes = AirlineNote & PersonalNote & Leader & HotelNote & ProfileNote;

export type RootAction = AuthAction | OrganizationAction | LeaderAction | AirlinesAction | StaticDataAction | 
PersonalAction | GlobalMessageAction | HotelsAction | ProfileNoteAction | 
ScheduleAction | GlobalModalAction | CabUIAction | EventAnalyticsAction | CRMAction | ScheduleUIAction;

export type RootState = ReturnType<ReturnType<typeof fabricateStore>['getState']>;
