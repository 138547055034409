
import auth from './auth/reducer';
import staticData from './staticData/reducer';
import organization from './organization/reducer';
import leaders from './leaders/reducer';
import airlines from './airlines/reducer';
import personalNotes from './personal/reducer';
import globalMessages from './globalMessages/reducer';
import hotels from './hotels/reducer';
import profileNotes from './profileNotes/reducer';
import schedule from './schedule/reducer';
import globalModal from './globalModal/reducer';
import cabUI from "./cabUI/reducer";
import eventAnalytics from './eventAnalytics/reducers';
import crm from './crm/reducers';
import scheduleUI from './scheduleUI';

const rootReducer = {
  auth,
  staticData,
  organization,
  leaders,
  airlines,
  personalNotes,
  globalMessages,
  hotels,
  profileNotes,
  schedule,
  globalModal,
  cabUI,
  eventAnalytics,
  crm,
  scheduleUI,
};

export default rootReducer;
