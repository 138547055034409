import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  additionalCalendarSelectionUpdated, calendarTimezoneUpdated, leaderSelectionUpdated, secondaryTimezonesUpdated,
  selectCalendarAccessToCalendarMap, selectCurrentOrNewMeeting,
  selectMeetingViewableAdditionalCalendars,
  timezonesInitialized, dateRangeUpdated
} from ".";
import { MeetingLeaderInfo, MeetingUpdate, RootState, ThunkDispatchType } from "..";
import { difference, isEqual, uniqBy } from "lodash-es";
import { 
  calendarToParticipant, getLocalTimeZone, getTimeZone, getWeekRange, NY_TZ 
} from "../../utils/scheduleUtils";
import {
  createMeetingParticipant, deleteMeetingParticipant, deleteNewMeeting, updateMeeting, updateNewMeeting
} from "../schedule/actions";
import { ActionType } from "../actionTypes";
import { selectOrderedSchedulingLeaders } from "../leaders/selectors";
import { DateTime } from "luxon";


// GUIDELINES:
// - Trigger effects in response to specific action(s) for simplicity and predictability. You may additionally
// check for a certain state condition if necessary.
// - If it simplifies overall logic, you may also trigger effects in response to state changes alone, but this should
// be less common.


export const scheduleListenerMiddleware = createListenerMiddleware();

export const startAppListening = scheduleListenerMiddleware.startListening.withTypes<
  RootState,
  ThunkDispatchType
>();

const getSelectedLeadersFromMeeting = (meetingLeaders: MeetingLeaderInfo[], schedulingLeaderIds: number[]) => {
  return {
    leaderIds: meetingLeaders.map(ml => ml.id).filter(id => schedulingLeaderIds.includes(id)),
    // leaders that the user does not have access to
    additionalLeaders: meetingLeaders.filter(ml => !schedulingLeaderIds.includes(ml.id)),
  };
};

// initialize leader selection
startAppListening({
  predicate: (action, state, previousState) => {
    const { schedule, leaders } = state;
    const meetingsLoaded = schedule.meetingsLoaded;
    const leadersLoaded = leaders.loaded;
    const prevLeadersLoaded = previousState.leaders.loaded;
    const prevMeetingsLoaded = previousState.schedule.meetingsLoaded;

    return (leadersLoaded !== prevLeadersLoaded || meetingsLoaded !== prevMeetingsLoaded) &&
      meetingsLoaded && leadersLoaded && leaders.leaders.length > 0;
  },
  effect: (action, { getState, dispatch }) => {
    const state = getState();
    const leadersForScheduling = selectOrderedSchedulingLeaders(state);
    const currentMeeting = selectCurrentOrNewMeeting(state);
    if (currentMeeting) {
      const { leaderIds, /*additionalLeaders*/ } = getSelectedLeadersFromMeeting(
        currentMeeting.leader_info || [], leadersForScheduling.map(l => l.id)
      );
      dispatch(leaderSelectionUpdated(leaderIds.map(id => ({ leaderId: id, selected: true }))));
      // TODO: eventually we want to show additional leaders in the leader list
    } else {
      dispatch(leaderSelectionUpdated([{ leaderId: leadersForScheduling[0].id, selected: true }]));
    }
  }
});

// Run if prefs are updated or fetched
startAppListening({
  predicate: (action, state) => {
    return action.type === ActionType.UPDATED_SCHEDULING_PREFS || 
           action.type === ActionType.FETCHED_SCHEDULING_PREFS;
  },
  effect: (action, { getState, dispatch }) => {
    const state = getState();
    const userPrefs = state.schedule.schedulingPrefs.user_prefs;

    dispatch(timezonesInitialized({
      primary: userPrefs?.default_calendar_timezone || getLocalTimeZone()?.name || NY_TZ.name,
      secondary: userPrefs?.default_secondary_timezone?.map(tz => 
        (tz ? getTimeZone(tz) || getLocalTimeZone() : getLocalTimeZone())?.name || NY_TZ.name
      ) || []
    }));

    // update the date range if the week start day is updated
    const { start, end } = getWeekRange(DateTime.now(), userPrefs?.week_start_day);
    dispatch(dateRangeUpdated({
      startTimestampMs: start.toMillis(),
      endTimestampMs: end.toMillis(),
    }));
  }
});

// run appropriate effects when current (saved) meeting is set or unset
startAppListening({
  // actionCreator: currentMeetingSet,
  predicate: (action, state, previousState) => {
    return previousState.scheduleUI.currentMeetingId !== state.scheduleUI.currentMeetingId;
  },
  effect: (action, { getState, dispatch }) => {
    const currentMeetingId = getState().scheduleUI.currentMeetingId;
    if (currentMeetingId != null) {
      const meeting = getState().schedule.meetings[currentMeetingId];
      if (meeting) { // current meeting became active
        dispatch(deleteNewMeeting());
      }
    } else { // current meeting became inactive
    }
  },
});

// sync current meeting timezones to selected timezones
startAppListening({
  predicate: (action, state, prevState) => {
    if (action.type !== calendarTimezoneUpdated.type && action.type !== secondaryTimezonesUpdated.type) return false;

    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);
    if (!currentOrNewMeeting) return false;
    
    return currentOrNewMeeting.calendar_tz !== state.scheduleUI.timezoneName
      || !isEqual(new Set(currentOrNewMeeting.secondary_tz || []), new Set(state.scheduleUI.secondaryTimezoneNames));
  },
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);

    if (currentOrNewMeeting) {
      let update: MeetingUpdate = {
        id: currentOrNewMeeting.id,
      };
      if (currentOrNewMeeting.calendar_tz !== state.scheduleUI.timezoneName) {
        update = { ...update, calendar_tz: state.scheduleUI.timezoneName };
      }

      const secondaryTzSet = new Set(state.scheduleUI.secondaryTimezoneNames);
      const mtgSecondaryTzSet = new Set((currentOrNewMeeting.secondary_tz || []).filter(tz => tz != null));
      if (!isEqual(mtgSecondaryTzSet, secondaryTzSet)) {
        update = { ...update, secondary_tz: Array.from(secondaryTzSet) };
      }

      listenerApi.dispatch(updateMeeting(update, []));
    }
  },
});

// sync current meeting leaders to selected leaders
startAppListening({
  actionCreator: leaderSelectionUpdated,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);
    if (!currentOrNewMeeting) return;

    const accessibleMeetingLeaders = (currentOrNewMeeting.leaders || [])
      .filter(id => state.leaders.leaders.some(l => l.id === id));
    if (isEqual(new Set(accessibleMeetingLeaders), new Set(state.scheduleUI.selectedLeaderIds))) {
      return;
    }

    const selectedLeaderIds = state.scheduleUI.selectedLeaderIds;

    // calling these 2 methods here gives us a debounce/takeLatest effect
    listenerApi.cancelActiveListeners();
    // wait slightly longer to update meeting or state if no leaders are selected
    await listenerApi.delay(selectedLeaderIds.length > 0 ? 750 : 2000);

    // revert leader selection if no leaders are selected
    if (selectedLeaderIds.length === 0 && currentOrNewMeeting.leaders?.length) {
      listenerApi.dispatch(
        leaderSelectionUpdated(currentOrNewMeeting.leaders.map(l => ({ leaderId: l, selected: true })))
      );
    } else {
      if (currentOrNewMeeting?.id != null) {
        const inaccessibleLeaderIds = (currentOrNewMeeting.leaders || [])
          .filter(id => !state.leaders.leaders.some(l => l.id === id));
        const updatedLeaders = [...selectedLeaderIds, ...inaccessibleLeaderIds];

        if (currentOrNewMeeting.id < 0) {
          listenerApi.dispatch(updateNewMeeting({
            ...currentOrNewMeeting,
            leaders: updatedLeaders
          }));
        } else {
          listenerApi.dispatch(updateMeeting({
            id: currentOrNewMeeting.id,
            leaders: updatedLeaders
          }, []));
        }
      }
    }
  },
});

// sync selected leaders to current meeting leaders
startAppListening({
  predicate: (action, state, prevState) => {
    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);
    if (!currentOrNewMeeting) return false;
    const prevCurrentOrNewMeeting = selectCurrentOrNewMeeting(prevState);
    if (!isEqual(new Set(currentOrNewMeeting.leaders || []), new Set(prevCurrentOrNewMeeting?.leaders || []))) {
      const accessibleMeetingLeaders = (currentOrNewMeeting.leaders || []).filter(id => 
        state.leaders.leaders.some(l => l.id === id)
      );
      
      // Only trigger if there's a difference between accessible meeting leaders and selected leader IDs
      return !isEqual(new Set(accessibleMeetingLeaders), new Set(state.scheduleUI.selectedLeaderIds));
    }
    return false;
  },
  effect: (action, { getState, dispatch }) => {
    const state = getState();
    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);
    if (!currentOrNewMeeting) return;

    const selectedLeaderIds = state.scheduleUI.selectedLeaderIds;

    const leadersForScheduling = selectOrderedSchedulingLeaders(state);
    const meetingLeaders = currentOrNewMeeting.leader_info || [];
    // TODO: eventually we want to show additional leaders in the leader list
    const { leaderIds, /*additionalLeaders*/ } = getSelectedLeadersFromMeeting(
      meetingLeaders, leadersForScheduling.map(l => l.id)
    );
    const toSelect = leaderIds.map(lId => ({ leaderId: lId, selected: true }));
    const toDeselect = selectedLeaderIds
      .filter(lId => !meetingLeaders.map(l => l.id).includes(lId))
      // Only deselect leaders that we have access to
      .filter(lId => state.leaders.leaders.some(l => l.id === lId))
      .map(lId => ({ leaderId: lId, selected: false }));

    dispatch(leaderSelectionUpdated([...toSelect, ...toDeselect]));
  }
});

// sync additional calendars to current meeting
startAppListening({
  predicate: (action, state, prevState) => {
    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);
    if (!currentOrNewMeeting) return false;
    const prevCurrentOrNewMeeting = selectCurrentOrNewMeeting(prevState);
    
    const meetingCalendars = selectMeetingViewableAdditionalCalendars(state, currentOrNewMeeting.id)
      .map(cal => cal.calendar_access_id);
    const prevMeetingCalendars = prevCurrentOrNewMeeting ? 
      selectMeetingViewableAdditionalCalendars(prevState, prevCurrentOrNewMeeting.id)
        .map(cal => cal.calendar_access_id) : [];

    // Only trigger if meeting calendars changed AND they're different from UI state
    if (!isEqual(new Set(meetingCalendars), new Set(prevMeetingCalendars))) {
      return !isEqual(new Set(meetingCalendars), new Set(state.scheduleUI.selectedAdditionalCalendarAccessIds));
    }
    return false;
  },
  effect: async (action, { getState, dispatch }) => {
    const state = getState();
    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);
    if (!currentOrNewMeeting) return;

    const calsToDeselect = getState().scheduleUI.selectedAdditionalCalendarAccessIds.map(
      calendarAccessId => ({ calendarAccessId, selected: false })
    );
    const calsToSelect = selectMeetingViewableAdditionalCalendars(state, currentOrNewMeeting.id)
      .map(cal => ({ calendarAccessId: cal.calendar_access_id, selected: true }));
    const allCals = uniqBy([...calsToSelect, ...calsToDeselect], ({ calendarAccessId }) => calendarAccessId);

    dispatch(additionalCalendarSelectionUpdated(allCals));
  },
});

// sync current meeting to additional calendars
startAppListening({
  actionCreator: additionalCalendarSelectionUpdated,
  effect: async (action, { getState, dispatch }) => {
    const state = getState();
    const currentOrNewMeeting = selectCurrentOrNewMeeting(state);

    if (!currentOrNewMeeting) return;

    const meetingAdditionalCalendarAccessIds = selectMeetingViewableAdditionalCalendars(state, currentOrNewMeeting.id)
      .map(cal => cal.calendar_access_id);

    if (isEqual(
      new Set(state.scheduleUI.selectedAdditionalCalendarAccessIds),
      new Set(meetingAdditionalCalendarAccessIds)
    )) return;

    const calendarMap = selectCalendarAccessToCalendarMap(state);

    const participants = Object.fromEntries(
      state.scheduleUI.selectedAdditionalCalendarAccessIds
        .map((calendarAccessPk) => calendarMap[calendarAccessPk])
        .filter(cal => cal)
        .map((cal, idx) => {
          const assignedId = (idx + 1) * -1;
          return [assignedId, calendarToParticipant(currentOrNewMeeting.id || -1, cal, assignedId)];
        })
    );

    if (currentOrNewMeeting.id < 0) {
      const nonCalendarParticipants = Object.fromEntries(
        Object.values(currentOrNewMeeting.participants || {})
          .filter(partItr => partItr.calendar_access === null)
          .map(partItr => [partItr.id, partItr])
      );
      dispatch(updateNewMeeting({
        ...currentOrNewMeeting,
        participants: {...nonCalendarParticipants, ...participants}
      }));
    } else {
      let createPromises: Promise<void>[] = [];
      const attachedCalendars = Object.values(currentOrNewMeeting.participants || {})
        .filter(part => !!part.calendar_access)
        .map(participant => participant.calendar_access);
        
      const newCalendars = Object.values(participants)
        .filter(participant => !attachedCalendars.includes(participant.calendar_access));
        
      const removeCalendars = difference(attachedCalendars, state.scheduleUI.selectedAdditionalCalendarAccessIds);
      
      // Create new calendar participants
      for (const participant of newCalendars) {
        createPromises.push(dispatch(createMeetingParticipant(participant)));
        if (createPromises.length === 5) {
          await Promise.all(createPromises);
          createPromises = [];
        }
      }
      await Promise.all(createPromises);

      // Remove old calendar participants
      const removePromises: Promise<void>[] = [];
      const removeIds = Object.values(currentOrNewMeeting.participants || {})
        .filter((participant) => removeCalendars.includes(participant.calendar_access))
        .map(participant => participant.id);

      for (const removeId of removeIds) {
        removePromises.push(
          dispatch(deleteMeetingParticipant(
            removeId, 
            currentOrNewMeeting.id || -1,
            !!currentOrNewMeeting.id
          ))
        );
        if (removePromises.length === 5) {
          await Promise.all(removePromises);
        }
      }
      await Promise.all(removePromises);
    }
  },
});
