const greens = {
  BorealForest100: '#D0E5DB',
  BorealForest200: '#A6C8B7',
  BorealForest300: '#73AE93',
  BorealForest400: '#27966D',
  BorealForest500: '#128160',
  BorealForest600: '#007854',
  BorealForest700: '#005A3F',
  BorealForest800: '#003D2B',
  BorealForest900: '#002318',
  mint100: '#FBFEFC',
  mint200: '#F7FEFA',
  mint300: '#EFFDF5',
  mint400: '#E7FCF0',
  mint500: '#DEFBEB',
  mint600: '#CDECDB',
  mint700: '#B1CCBE',
  mint800: '#91A79B',
  mint900: '#66766D',
};

export const colors = {
  redError: '#E21616',

  black900: '#2A2A39',
  black800: '#50505D',
  black700: '#6C6C76',
  black600: '#83838C',
  black500: '#9D9DA4',
  black400: '#B9B9BE',
  black300: '#CCCCCF',
  black200: '#DFDFE1',
  black100: '#EEEEEF',
  black50: '#F6F6F7',

  white900: '#FFFFFF',
  white800: '#DDDDDF',
  white700: '#C1C1C6',
  white600: '#A6A6AC',
  white500: '#8C8C94',
  white400: '#72727C',
  white300: '#5B5B67',
  white200: '#484855',
  white100: '#2A2A39',
  white50: '#333341',

  purplePrimary: '#8282D8',
  purpleDark: '#4D4D80',
  purpleLight: '#F0F0FC',

  greenPrimary: greens.BorealForest500,
  greenDark: greens.BorealForest700,
  greenLight: greens.BorealForest300,

  coralPrimary: '#FF8F6D',
  coralLight: '#F99C80',

  neutral: '#F0F0F2',

  navyDark: '#252D49',
  navyPrimary: '#3A4877',
  navyLight: '#4F66A5',

  blueLight: '#5CB9F2',
  greyBackdrop: '#FAFAFD',
  lightBackgroundGreen: greens.mint300,

  // NEW THEME BELOW

  chamomile50: '#FDFEF6',
  chamomile100: '#FBFEE8',
  chamomile200: '#FAFDD1',
  chamomile300: '#F9FCB9',
  chamomile400: '#FAFAA6',
  chamomile500: '#F8F38D',
  chamomile600: '#F7E56D',
  chamomile700: '#EFC937',
  chamomile800: '#D1A630',
  chamomile900: '#A87E29',
  chamomile950: '#735625',

  forest50: '#FDFFFC',
  forest100: '#FBFEF9',
  forest200: '#F3FFF3',
  forest300: '#EAFDEA',
  forest400: '#D6FFD3',
  forest500: '#BFFFC3',
  forest600: '#AFF7A1',
  forest700: '#9CE489',
  forest800: '#76BE65',
  forest900: '#579D47',
  forest950: '#437138',

  lavendar50: '#F7F7FE',
  lavendar100: '#F0EFFE',
  lavendar200: '#EAE8FD',
  lavendar300: '#E1E1FF',
  lavendar400: '#D4D2FB',
  lavendar500: '#C8BFFF',
  lavendar600: '#A08FF1',
  lavendar700: '#8770E0',
  lavendar800: '#7255C2',
  lavendar900: '#573D99',
  lavendar950: '#2C1F77',

  sunset50: '#FEF7F7',
  sunset100: '#FCF2F2',
  sunset200: '#FCEAE8',
  sunset300: '#F9DEDE',
  sunset400: '#F7CCCB',
  sunset500: '#FFC3C3',
  sunset600: '#F1ABA9',
  sunset700: '#EA8481',
  sunset800: '#DE5F59',
  sunset900: '#BB423A',
  sunset950: '#782923',

  calmGrey50: '#F8FAF9',
  calmGrey100: '#F2F4F3',
  calmGrey150: '#EAEDEC',
  calmGrey200: '#E2E6E5',
  calmGrey300: '#C1CAC7',
  calmGrey400: '#829A93',
  calmGrey500: '#5D7B74',
  calmGrey600: '#346256',
  calmGrey700: '#2D544B',
  calmGrey800: '#25453D',
  calmGrey900: '#1A312B',

  dark950: '#0E0919',
  dark900: '#1A1426',
  dark800: '#2B233C',
  dark700: '#383247',
  dark600: '#493D62',
  dark500: '#675D7B',
  dark400: '#8A829A',
  dark300: '#C4C1CA',
  dark100: '#E3E2E6',
  dark50: '#F3F2F4',

  systemBlack: '#0E0919',
  systemNeutral: '#FDF9F7',
  systemWhite: '#FFFFFF',
  ink: '#0E0919',
};

export default colors;