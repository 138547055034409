import React, { ReactElement } from "react";
import { Avatar, AvatarProps, Badge } from "@mui/material";
import theme from "../../../theme";
import { CabComponentProps } from "../cabStyled";
import colors from "../../../colors";
import { CabIcon } from "../CabIcon";
import { CabTooltip } from "../CabTooltip";
import { IoPeopleCircle } from "react-icons/io5";

type AvatarSize = "large" | "intermediate" | "medium" | "small";

export interface CabAvatarProps extends CabComponentProps<AvatarProps> {
  color?: string;
  name?: string;
  src?: AvatarProps['src'] | null;
  className?: AvatarProps['className'];
  size?: AvatarSize;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  isShared?: boolean;
  sharedBy?: string;
}

const stringAvatar = (
  value: string | null, color: string, size?: AvatarSize, src?: string | null, sx?: AvatarProps['sx']
): {children: string, sx: AvatarProps['sx']} => {
  const nameSpaceArray = value ? value.split(" ").filter(str => str !== "") : [];
  let firstName = "";
  let lastName = "";
  let firstNameBreak = 0;
  let lastNameBreak = 0; 
  let initials = "";

  if (value && value.length > 0) {
    for (let i = 0; i < nameSpaceArray.length; i++) {
      if (!prefixSuffixArray.includes(nameSpaceArray[i])) {
        firstName = nameSpaceArray[i].toUpperCase();
        firstNameBreak = i;
        break;
      }
    }

    const nameSpaceArrayReverse = nameSpaceArray.reverse();

    for (let i = 0; i < nameSpaceArrayReverse.length; i++) {
      if (!prefixSuffixArray.includes(nameSpaceArrayReverse[i])) {
        lastName = nameSpaceArrayReverse[i].toUpperCase();
        lastNameBreak = nameSpaceArrayReverse.length - 1 - i;
        break;
      }
    }
    const firstInitial = firstName[0] ? firstName[0].toUpperCase() : "";
    const lastInitial = lastName[0] ? lastName[0].toUpperCase() : "";
    
    initials = firstInitial + lastInitial;

    if (firstNameBreak === lastNameBreak) {
      initials = firstInitial;
    }
  }

  return {
    children: initials,
    sx: {
      bgcolor: src ? undefined : color,
      ...getStyle(size),
      ...sx,
    }
  };
};

export const CabAvatar = (
  {sx, name = "", src, className, color = colors.white900, size, onClick, isShared, sharedBy}: CabAvatarProps
): ReactElement => {
  const avatarProps = React.useMemo(() => stringAvatar(name, color, size, src, sx), [name, color, size, src, sx]);
  if (isShared) {
    return <Badge
      overlap="circular"
      sx={{zIndex: 0}}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <CabTooltip placement="bottom-end" arrow title={sharedBy ? `Shared by ${sharedBy}` : 'Shared'} wrapWithSpan>
          <Avatar sx={{ backgroundColor: colors.white900, width: 16, height: 16 }}>
            <CabIcon Icon={IoPeopleCircle} sx={{color: colors.black600, fontSize: 18}}/>
          </Avatar>
        </CabTooltip>
      }
    >
      <Avatar src={src || undefined} {...avatarProps} onClick={onClick} className={className}/>
    </Badge>;
  }
  return <Avatar src={src || undefined} {...avatarProps} onClick={onClick} className={className}/>;
};

export default CabAvatar;

const getStyle = (size?: AvatarSize ) => {
  let style = { width: theme.spacing(6), height: theme.spacing(6), fontSize: theme.spacing(3) };
  switch (size) {
    case "large":
      style = {
        width: "80px",
        height: "80px",
        fontSize: "40px !important"
      };
      break;
    case "intermediate":
      style = {
        width: "48px",
        height: "48px",
        fontSize: "18px !important"
      };
      break;
    case "medium":
      style = {
        width: "28px",
        height: "28px",
        fontSize: "14px !important"
      };
      break;
    case "small":
      style = { 
        width: "24px",
        height: "24px",
        fontSize: "12px !important"
      };
      break;
  }
  return style;
};

const prefixSuffixArray: string[] = [
  "Br",
  "Chan",
  "Chapln",
  "Dr",
  "Fr",
  "Gov",
  "Miss",
  "Mr",
  "Mrs",
  "Ms",
  "Mme",
  "M",
  "Msgr",
  "Pres",
  "Prof",
  "Rabbi",
  "Rep",
  "Rev",
  "Revs",
  "Sen",
  "Sr",
  "Sra",
  "rta",
  "Sr",
  "The Hon",
  "Esq",
  "V",
  "IV",
  "Jr",
  "II",
  "Sr",
  "III",
  "Ret",
  "USA",
  "USAF",
  "SMC",
  "USN",
  "Br.",
  "Chan.",
  "Chapln.",
  "Dr.",
  "Fr.",
  "Gov.",
  "Miss.",
  "Mr.",
  "Mrs.",
  "Ms.",
  "Mme.",
  "M.",
  "Msgr.",
  "Pres.",
  "Prof.",
  "Rabbi.",
  "Rep.",
  "Rev.",
  " Revs.",
  "Sen.",
  "Sr.",
  "Sra.",
  "rta.",
  "Sr.",
  "The Hon.",
  "Esq.",
  "V.",
  "IV.",
  "Jr.",
  "II.",
  "Sr.",
  "III.",
  "Ret.",
  "USA.",
  "USAF.",
  "SMC.",
  "USN."
];
