import 
React, { FocusEvent, ForwardedRef, ReactElement, RefCallback, useCallback, useMemo, useRef, useState } 
  from "react";
import { Box, PopoverProps, SxProps, useMediaQuery, styled, useTheme, InputBaseComponentProps } from "@mui/material";
import { Mention, MentionsInput, MentionsInputProps } from 'react-mentions';
import { CabTextInput, CabTextInputProps } from "../CabTextInput";
import { CabIcon } from "../CabIcon";
import { CabCollapseMenu } from "../CabCollapseMenu";
import { CabButton, CabButtonProps } from "../CabButton";
import colors from "../../../colors";
import { CabComponentProps } from "../cabStyled";
import { CabTooltip } from "../CabTooltip";
import { IoAddOutline } from "react-icons/io5";


export interface TemplateVars {
  [varName: string]: {
    group: string;
    explanation: string;
    replaceText: string;
    disabled?: boolean;
  }
}

interface TemplateVarGroups {
  [groupName: string]: {
    [varName: string]: string;
  }
}

export interface TemplateVarMenuProps {
  target?: ReactElement | string;
  passedAnchorEl?: Element | null;
  passedAnchorOrigin?: PopoverProps["anchorOrigin"];
  passedTransformOrigin?: PopoverProps["transformOrigin"];
  templateVars: TemplateVars;
  onClose?: () => void;
  onClickVar: (varName: string) => void;
  onAddQuestion?: () => void;
  setIsOpen?: (open: boolean) => void;
  sx?: SxProps;
  isPoll?: boolean;
}

const StyledGroupButton = styled(CabButton, {label: "StyledGroupButton"}
)<CabComponentProps<CabButtonProps>>(({ theme}) => ({
  color: theme.palette.mode === 'dark' 
    ? colors.white900 
    : colors.black900,
  justifyContent: 'start',
  paddingBottom: 8,
  paddingTop: 8,
  paddingLeft: 15,
  paddingRight: 15,
  width: '100%'
}));

export const TemplateVarMenu = ({ 
  target, passedAnchorEl, passedAnchorOrigin, passedTransformOrigin, templateVars, 
  onClose, onClickVar, setIsOpen, onAddQuestion, sx, isPoll
}: TemplateVarMenuProps): ReactElement => {
  const theme = useTheme();

  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const requiredQuestionText = 'Questions must be set to "Required" to be used as variables.';

  const varGroups = useMemo(() => {
    const groups: TemplateVarGroups = {};
    Object.keys(templateVars).forEach((varName) => {
      const group = templateVars[varName].group;
      if (group in groups) {
        groups[group][varName] = templateVars[varName].explanation;
      } else {
        groups[group] = {[varName]: templateVars[varName].explanation};
      }
    });
    return groups;
  }, [templateVars]);

  return (
    <CabCollapseMenu
      sx={{ ...sx, display: 'none' }}
      onClose={onClose}
      setIsOpen={setIsOpen}
      passedAnchorOrigin={passedAnchorOrigin}
      passedTransformOrigin={passedTransformOrigin}
      passedAnchorEl={passedAnchorEl}
      buttonGroupSx={{ paddingBottom: 1, minWidth: isSmDown ? "120px" : "190px"}}
      buttonGroupColor="inherit"
      buttonGroupVariant="outlined"
      target={target}
      buttons={<>
        {Object.keys(varGroups).map((groupName, index) => (
          <Box
            key={`${groupName}-${index}`}
            sx={{
              borderTop: index === 0 ? undefined : "1px solid grey",
              paddingTop: 1,
              paddingLeft: 1,
              color: colors.white600,
              fontSize: 14,
            }}
          >
            {groupName}
            {Object.keys(varGroups[groupName]).map((varName, i) => (
              <CabTooltip
                key={`${varName}-${i}`}
                title={templateVars[varName].disabled ? requiredQuestionText : ''}
                placement="bottom"
                wrapWithSpan
              >
                <StyledGroupButton 
                  buttonType="text"
                  disabled={templateVars[varName].disabled}
                  onClick={() => onClickVar(varName)} 
                >
                  {templateVars[varName].explanation}
                </StyledGroupButton>
              </CabTooltip>
            ))}
          </Box>
        ))}
        {onAddQuestion &&
          <Box
            sx={{
              borderTop: "1px solid grey",
              paddingTop: 1,
              paddingLeft: 1,
              color: colors.white600,
              fontSize: 14,
            }}
          >
            <StyledGroupButton
              buttonType="text"
              icon={<CabIcon color={colors.black900} Icon={IoAddOutline} alt='add'/>}
              onClick={() => onAddQuestion()}
            >
              Add Question
            </StyledGroupButton>
          </Box>
        }
      </>}
      popOverTitle=""
      popOverAnchorOrigin={{
        horizontal: -100,
        vertical:"bottom"
      }}
    />
  );
};


const nonBreakingSpace = String.fromCharCode(160);

const TokenInput = React.forwardRef((
  { templateVars, tokenColor, ...props }: { templateVars: TemplateVars; tokenColor: string } & MentionsInputProps,
  ref: ForwardedRef<HTMLInputElement|null>,
) => {
  const data = Object.entries(templateVars).map(([variable, { explanation, group, replaceText }]) => ({
    id: variable,
    display: replaceText,
  }));

  return (
    <MentionsInput
      inputRef={ref}
      {...props}
      allowSpaceInQuery
      placeholder={props.placeholder}
      style={{
        width: '100%',
        height: '100%',
        input: {
          outline: 'none', borderWidth: 0, fontFamily: 'Inter',
          ...(props.singleLine ? { marginTop: 6, marginLeft: 8 } : { overflow: 'auto' }),
        },
        highlighter: {
          borderLeftWidth: 2,
          ...(props.singleLine
            ? { marginTop: -2, marginLeft: -6 }
            : { marginTop: 2, boxSizing: 'border-box', overflow: 'hidden' }),
          ...props.style,
        },
        control: {
          ...props.style,
          lineHeight: 'initial',
        },
      }}
      onKeyDownCapture={(e) => {
        // If character key press happens when cursor is inside a token, move the cursor to beginning or end of token
        const cursorPosition = e.currentTarget.selectionEnd || 0;
        const visibleTextBeforeCursor = (e.currentTarget.value || '').substring(0, cursorPosition);
        // since all tokens contain a pair of nonBreakingSpace, if we count an odd number the cursor is inside a token
        if ((visibleTextBeforeCursor.match(new RegExp(nonBreakingSpace, 'g')) || []).length % 2 !== 0) {
          // seems to cover most single character inputs that are not delete or directional keys
          if (e.key.length === 1) {
            const visibleTextAfterCursor = (e.currentTarget.value || '').substring(cursorPosition);
            const tokenStartIndex = visibleTextBeforeCursor.lastIndexOf(nonBreakingSpace);
            const tokenEndIndex = visibleTextAfterCursor.indexOf(nonBreakingSpace);
            if (tokenStartIndex !== -1 && tokenEndIndex !== -1) {
              const distanceToTokenStart = cursorPosition - tokenStartIndex;
              const distanceToTokenEnd = tokenEndIndex;
              const newCursorIndex = distanceToTokenStart < distanceToTokenEnd
                ? cursorPosition - distanceToTokenStart
                : cursorPosition + distanceToTokenEnd + 1;
              e.currentTarget.selectionStart = newCursorIndex;
              e.currentTarget.selectionEnd = newCursorIndex;
            }
          }
        }
      }}
    >
      <Mention
        data={data}
        trigger="{{"
        renderSuggestion={() => null}
        displayTransform={(_, display) => {
          const displayToken = `${nonBreakingSpace}${display}${nonBreakingSpace}`;
          return displayToken;
          // return display.length > 15 ? `\n${displayToken}` : displayToken;
        }}
        markup="{{__id__}}(__display__)"
        style={{ backgroundColor: tokenColor || 'gray', borderRadius: 4 }}
      />
    </MentionsInput>
  );
});

export interface CabTextTokenInputProps extends CabTextInputProps {
  onChange: Required<CabTextInputProps>['onChange'];
  onBlur?: (e: FocusEvent<HTMLInputElement>, latestValue?: string) => void;
  inputRef?: RefCallback<HTMLInputElement>;
  onAddQuestion?: () => void;
  templateVars: TemplateVars;
  value: string;
  callOnBlur?: boolean;
  tokenColor?: string;
  id?: string;
}


export const CabTextTokenInput = ({
  value, onChange, onBlur, endIcon, inputRef, onAddQuestion, callOnBlur, sx, templateVars, id,
  tokenColor, inputProps, ...props
}: CabTextTokenInputProps): ReactElement => {
  const titleRef = useRef<HTMLTextAreaElement | null>(null);
  const [templateVarInputEl, setTemplateVarInputEl] = useState<Element | null>(null);
  const [position, setPosition] = useState<number | undefined>(undefined);

  const memoizedTemplateVarInputOnChange = useCallback((varName: string, cursorPosition?: number) => {
    // NOTE: the value we have here is the markdown text, but the cursor position is in relation to
    // the visible text. In order to insert a new variable correctly we must translate the cursor position
    // from visible to markup.
    const templateVarsLookup = Object.entries(templateVars)
      .map(([k, v]) => ({ [v.replaceText]: k }))
      .reduce((a, b) => ({ ...a, ...b }), {});
    const prev = value;
    const pos = cursorPosition != null ? cursorPosition : prev.length;
    let markupTextCursorPos = 0;
    if (pos !== 0) {
      const visibleTextBeforeCursor = (titleRef.current?.value || '').substring(0, pos);

      const visibleTokenMatchesRes = visibleTextBeforeCursor.matchAll(new RegExp(
        `${nonBreakingSpace}.+?${nonBreakingSpace}`, 'gi'
      ));

      let markupTextBeforeCursor = visibleTextBeforeCursor;
      // NOTE: this could potentially fail if one variable name is a substring of another variable name.
      // We should really enforce visible token uniqueness
      [...visibleTokenMatchesRes].forEach(([match]) => {
        const cleanMatch = match.trimStart().trimEnd();
        const matchedVarName = templateVarsLookup[cleanMatch];
        markupTextBeforeCursor = markupTextBeforeCursor.replaceAll(match, `{{${matchedVarName}}}(${cleanMatch})`);
      });

      // if we get an actual index from this we are in the middle of a token
      const beginningOfTokenCursorIsIn = markupTextBeforeCursor.indexOf(nonBreakingSpace);
      if (beginningOfTokenCursorIsIn !== -1) {
        // set position to beginning of token
        markupTextCursorPos = beginningOfTokenCursorIsIn;
      } else {
        markupTextCursorPos = markupTextBeforeCursor.length;
      }
    }

    const markupToken = `{{${varName}}}(${templateVars[varName]?.replaceText ?? varName})`;

    const newValue = prev.slice(0, markupTextCursorPos) + markupToken + prev.slice(markupTextCursorPos);
    onChange({ target: { value: newValue } } as React.ChangeEvent<HTMLInputElement>);
    if (callOnBlur && onBlur) {
      onBlur({ target: { value: newValue } } as React.FocusEvent<HTMLInputElement>, newValue);
    }
  }, [callOnBlur, onBlur, onChange, templateVars, value]);

  return (
    <>
      <CabTextInput
        {...props}
        value={value}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        inputRef={instance => {
          if (inputRef) inputRef(instance);
          titleRef.current = instance;
        }}
        InputProps={{
          inputComponent: TokenInput as unknown as React.ElementType<InputBaseComponentProps>,
          inputProps: {
            tokenColor, templateVars, singleLine: !props.multiline, placeholder: props.placeholder,
            ...inputProps,
          },
        }}
        sx={{ position: 'relative', ...sx }}
        endIcon={
          <Box
            sx={{ position: 'absolute', bottom: 2, right: 2, '&:hover': {cursor: "pointer"}}}
            onClick={e => {
              setPosition(titleRef.current?.selectionEnd);
              setTemplateVarInputEl(e.currentTarget);
            }} 
          >
            <CabIcon
              Icon={IoAddOutline} 
              sx={{width: 20, height: 20}}
            />
          </Box>}
      />
      <TemplateVarMenu 
        templateVars={templateVars}
        passedAnchorEl={templateVarInputEl}
        onClose={() => setTemplateVarInputEl(null)}
        onClickVar={(varName: string) => {
          memoizedTemplateVarInputOnChange(varName, position);
          setTemplateVarInputEl(null);
        }}
        onAddQuestion={onAddQuestion}
      />
    </>
  );
};

export const transformMarkupToSimpleTemplate = (markup: string | undefined) => {
  return markup?.replaceAll(/({{.+?}})\(.+?\)/gi, '$1') || "";
};

export const transformMarkupToText = (markup: string | undefined) => {
  return markup?.replaceAll(/{{(.+?)}}\((.+?)\)/gi, '[$2]') || "";
};

export const transformSimpleTemplateToMarkup = (
  markup: string | undefined, variableToVisible: Record<string, string>
) => {
  return markup?.replaceAll(/{{(.+?)}}/gi, (substring, match) => `{{${match}}}(${variableToVisible[match]})`) || "";
};

export const transformSimpleTemplateToText = (
  markup: string | undefined, variableToVisible: Record<string, string>
) => {
  return markup?.replaceAll(/{{(.+?)}}/gi, (substring, match) => (
    variableToVisible[match]
      ? `[${variableToVisible[match]}]`
      : match ? `[${match[0].toUpperCase()}${match.substring(1)}]` : '[]'
  )) || "";
};
