import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";
import { DateTime } from "luxon";
import { FilterSortParams } from "../api";
import { camelCase, capitalize } from "lodash-es";


export const removeKeysFromObject = <T extends object>(
  raw: T, keys: Array<string | number>
) => {
  const stringifiedKeys = keys.map((key) => {
    return typeof key !== 'string' ? key.toString() : key;
  });

  return Object.keys(raw)
    .filter(key => !stringifiedKeys.includes(key))
    .map((key) => ({ [key]: raw[key as keyof T] }))
    .reduce((a, b) => ({ ...a, ...b }), {});
};

const muiToApi: Record<string, string> = {
  'onOrAfter': '__gte',
  'onOrBefore': '__lte',
  '<': '__lt',
  '<=': '__lte',
  '>': '__gt',
  '>=': '__gte',
};

const muiToRTKQ: Record<string, string> = {
  'onOrAfter': 'Gte',
  'onOrBefore': 'Lte',
  '<': 'Lt',
  '<=': 'Lte',
  '>': 'Gt',
  '>=': 'Gte',
  'contains': 'Icontains',
  'isAnyOf': 'In',
};

export const transformMuiDataGridFilterSortToApiModel = <T extends object>({
  sortModel, filterModel
}: { sortModel?: GridSortModel; filterModel?: GridFilterModel; }, rtkqTransform?: boolean): T => {
  const dataSort = sortModel?.map(
    model => `${model.sort === "asc" ? "" : "-"}${model.field}`
  ) as FilterSortParams<T>['order_by'];

  const currentQuery = filterModel ? {
    topology: Array.isArray(filterModel.logicOperator) ? filterModel.logicOperator[0] : filterModel.logicOperator,
    ...filterModel.items.map(item => {
      if (item.value) {
        const value = item.value instanceof Date ? item.value.toISOString() : item.value;

        const muiOperator = item["operator"];
        if (muiOperator && !['eq', 'is', '=', 'equals'].includes(muiOperator)) {
          const apiOperator = rtkqTransform
            ? (muiToRTKQ[muiOperator] || capitalize(muiOperator))
            : muiToApi[muiOperator] || `__${muiOperator}`;
          return {
            [`${rtkqTransform ? camelCase(item.field) : item.field}${apiOperator}`]: value
          };
        }

        return { [rtkqTransform ? camelCase(item.field) : item.field]: value };
      }
      return undefined;
    }).filter(a => a).reduce((a, b) => ({ ...a, ...b }), {})
  } : {};

  return { [rtkqTransform ? 'orderBy' : 'order_by']: dataSort, ...currentQuery } as T;
};

let lastUsedId: number | undefined;

export const generateUniqueUnsavedCabinetId = () => {
  let newId = -Math.floor(DateTime.now().toMillis() / 1000);
  if (lastUsedId != null && lastUsedId <= newId) {
    newId = lastUsedId - 1;
  }
  lastUsedId = newId;

  return newId;
};
