import { ReactElement, useMemo, useState } from 'react';
import { 
  PickersDay, DatePicker, DatePickerProps,
  PickersDayProps, DatePickerToolbarProps,
  StaticDatePicker
} from '@mui/x-date-pickers-pro';
import {DateTime} from 'luxon';
import { getWeekRange } from '../../../utils/scheduleUtils';
import { CabComponentProps } from '../cabStyled';
import colors from "../../../colors";
import { CabInput } from '../CabInput';
import { Box, InputProps, styled, TextFieldProps, Typography } from '@mui/material';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
import { CabTimePicker } from '@CabComponents/CabTimePicker';
import { WeekStartDay } from '../../../store';


export interface CabDatePickerProps extends CabComponentProps<DatePickerProps<DateTime>> {
  value: string | null;
  onChange: (newDate: string | null) => void;
  clearable?: boolean;
  disabled?: boolean;
  type?: 'week' | 'day' | 'work_week';
  futureOnly?: boolean;
  size?: InputProps['size']
  onUpdate?: () => void;
  showTimePicker?: boolean;
  onClose?: () => void;
  allowedDates?: DateTime[];
  static?: boolean;
  noActionBar?: boolean;
  condensed?: boolean;
  arrowInteractionOnly?: boolean;
  weekStartDay?: WeekStartDay | null;
}

const CabInputStyled = styled(CabInput, {
  label: "CabInputStyled" }
)<TextFieldProps>(({ size }) => ({
  '& .MuiOutlinedInput-root': {
    height: 40,
    '.MuiInputBase-input.Mui-disabled': {
      backgroundColor: 'transparent'
    }
  },
  '&:disabled': {
    backgroundColor: 'red'
  },
  ...(size === 'small' && {
    '& .MuiOutlinedInput-root': {
      height: 32,
      fontSize: 14,
    },
    '& .MuiInputAdornment-root': {
      margin: 0,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        paddingLeft: 0
      }
    }
  })
}));


const ArrowInteractionOnly = (props: TextFieldProps & { inputProps: { value: string } }) => (
  <Typography fontSize={22} fontFamily="Satoshi" fontWeight={800}>{props.inputProps.value}</Typography>
);


export const CabDatePicker = ({ 
  value, onChange, clearable, disabled, type = 'day', size, sx, futureOnly, onUpdate, onClose,
  showTimePicker = false, allowedDates, static: isStatic, noActionBar = false, condensed = false,
  arrowInteractionOnly = false, weekStartDay = WeekStartDay.SUNDAY
}: CabDatePickerProps): ReactElement => {
  const [open, setOpen] = useState(false);
  const [hoverDate, setHoverDate] = useState<DateTime | undefined>();

  const date = value ? DateTime.fromISO(value) : null;

  const { highlightedDays, hoverDays, weekString, simpleString } = useMemo(() => {
    let highlighted: DateTime[] = [];
    let hover: DateTime[] = [];
    let weekStr: string | undefined;
    let simpleStr = date ? date.toFormat('MMMM d') : undefined;

    if (date && ['week', 'work_week'].includes(type)) {
      let { start, end } = getWeekRange(date, weekStartDay); 
      if (type === 'work_week') {
        start = start.plus({day: weekStartDay === WeekStartDay.SUNDAY ? 1 : 0});
        end = end.minus({day: weekStartDay === WeekStartDay.SUNDAY ? 1 : 2});
      }
      highlighted = [start, end];

      weekStr = `${start.toFormat('MM/dd')} to ${end.toFormat('MM/dd')}`;
      simpleStr = end.month !== start.month
        ? start.toLocaleString({ month: 'short', day: 'numeric' })
          + ' - ' + end.toLocaleString({ month: 'short', day: 'numeric' })
        : start.toLocaleString({ month: 'long', day: 'numeric' })
          + ' - ' + end.toLocaleString({ day: 'numeric' });
    }

    if (hoverDate && ['week', 'work_week'].includes(type)) {
      let { start, end } = getWeekRange(hoverDate, weekStartDay); 
      if (type === 'work_week') {
        start = start.plus({day: weekStartDay === WeekStartDay.SUNDAY ? 1 : 0});
        end = end.minus({day: weekStartDay === WeekStartDay.SUNDAY ? 1 : 2});
      }
      hover = [start, end];
    }

    return { highlightedDays: highlighted, hoverDays: hover, weekString: weekStr, simpleString: simpleStr };
  }, [date, type, hoverDate, weekStartDay]);

  const placeholderText = ['week', 'work_week'].includes(type) ? 'Select week' : 'Select date';
  const dateStr = date ? date.toLocaleString(DateTime.DATE_SHORT) : undefined;
  
  const actions = useMemo(() => {
    const pickerActions: PickersActionBarAction[] = showTimePicker ? [] : ["today"];
    if (clearable) {
      pickerActions.push("clear");
    }
    return pickerActions;
  }, [clearable, showTimePicker]);

  const handleOnChange = onUpdate ? (e: DateTime|null) => {
    onChange(e?.toISO() || null);
    onUpdate();
  } : (e: DateTime|null) => onChange(e?.toISO() || null);

  const commonProps: DatePickerProps<DateTime> = {
    shouldDisableDate: allowedDates ? (d => !allowedDates.find(ad => d.hasSame(ad, 'day'))) : undefined,
    format: 'MM/dd/yyyy',
    disabled,
    value: date,
    // this makes sure any dates used with this component are in the same timezone as the given value
    timezone: date?.zoneName || undefined,
    onChange: handleOnChange,
    closeOnSelect: !showTimePicker,
    showDaysOutsideCurrentMonth: true,
    minDate: futureOnly ? DateTime.now() : undefined,
    sx: {
      backgroundColor: 'transparent',
      ...(condensed && {
        '& .MuiDateCalendar-root': {
          // width: 290,
          width: 250,
          margin: 0,
          // marginLeft: '-10px',
          maxHeight: 312,
        },
        '& .MuiPickersCalendarHeader-root': {
          paddingLeft: 1,
          paddingRight: 0,
          marginTop: 0,
        }
      }),
      '& .MuiPickersDay-root': {
        transition: 'none'
      },
      '& .MuiYearCalendar-root': {
        width: 'unset',
      },
      '& .MuiPickersYear-yearButton.Mui-selected': {
        backgroundColor: colors.lavendar700,
      }
    },
    slots: {
      actionBar: noActionBar ? () => null : undefined,
      textField: arrowInteractionOnly ? ArrowInteractionOnly : CabInputStyled,
      // @ts-expect-error need custom props
      day: Day,
      toolbar: (props: DatePickerToolbarProps<DateTime>) =>  showTimePicker ? <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          bottom: 12,
          right: 12,
          zIndex: 10
        }}
      >
        <Typography sx={{marginRight: 1}} >Select a Time</Typography>
        <CabTimePicker
          pickerTime={date ? `${date.hour}:${date.minute}` : ""}
          onChange={(newTime) => {
            if (date) {
              const dateTime = date;
              const [hours, minutes] = newTime.split(":");
              const timeUpdate = DateTime.fromObject({
                ...dateTime.toObject(),
                hour: Number(hours),
                minute: Number(minutes)
              });
              handleOnChange(timeUpdate);
            }
          }}
        />
      </Box> : <></>,
    },
    slotProps: {
      actionBar: {
        actions
      },
      desktopPaper: {
        sx: {
          display: "flex",
          flexDirection: "column",
        }
      },
      textField: {
        placeholder: placeholderText,
        onClick: () => setOpen(true),
        size,
        inputProps: {
          readOnly: true,
          value: arrowInteractionOnly ? simpleString : weekString || dateStr || undefined,
          sx,
        }
      },
      day: {
        // @ts-expect-error need custom props
        onHoverDate: setHoverDate, highlightedDays, hoverDays, rangeType: type,
        // sx: { marginTop: 0, marginBottom: 0, marginLeft: '1px', marginRight: '1px' }
        sx: { margin: 0 }
      },
    }
  };

  return isStatic ? (
    <StaticDatePicker {...commonProps} />
  ) : (
    <DatePicker
      {...commonProps}
      open={disabled ? false : open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false);
        if (onClose) {
          onClose();
        }
      }}
    />
  );
};

export default CabDatePicker;

const highlightedColor = colors.lavendar700;
const hoverColor = colors.lavendar900;
const highlightedCurrentDayColor = colors.lavendar600;

const Day = (pickersDayProps: PickersDayProps<DateTime> & {
  highlightedDays: DateTime[]; hoverDays: DateTime[]; onHoverDate: ((day?: DateTime) => void);
  rangeType: 'day'|'week'|'work_week'
}) => {
  const { onHoverDate, highlightedDays, hoverDays, rangeType, ...props } = pickersDayProps;
  const newProps = { ...props };
  const day = newProps.day;

  if (['week', 'work_week'].includes(rangeType)) {
    newProps.autoFocus = false;
    const [highlightedStartDay, highlightedEndDay] = highlightedDays;
    const [hoverStartDay, hoverEndDay] = hoverDays;

    const dayInHighlightedRange = day >= highlightedStartDay && day <= highlightedEndDay;

    const dayInHoverRange = day >= hoverStartDay && day <= hoverEndDay;

    if (dayInHighlightedRange) {
      newProps.disableMargin = true;
      newProps.sx = (theme) => ({
        borderTopLeftRadius: highlightedStartDay.hasSame(day, 'day') ? undefined : 0,
        borderBottomLeftRadius: highlightedStartDay.hasSame(day, 'day') ? undefined : 0,
        borderTopRightRadius: highlightedEndDay.hasSame(day, 'day') ? undefined : 0,
        borderBottomRightRadius: highlightedEndDay.hasSame(day, 'day') ? undefined : 0,
        // width: 40,
        // width: 40,
        margin: 0,
        backgroundColor: highlightedColor,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
          backgroundColor: highlightedColor,
        },
        '&.Mui-selected': {
          backgroundColor: highlightedColor,
          '&:hover': {
            backgroundColor: highlightedColor,
          },
          '&:focus': {
            backgroundColor: highlightedColor,
          }
        },
        '&.MuiPickersDay-today': {
          borderWidth: 0,
          backgroundColor: highlightedCurrentDayColor,
        },
        '&.MuiPickersDay-root:not(.Mui-selected)':{
          // borderWidth: 2,
          // borderTop: 'none',
          // borderBottom: 'none',
          // borderColor: colors.white900
          // borderWidth: 0,
        }
      });
    } else if (dayInHoverRange) {
      newProps.disableMargin = true;
      newProps.sx = (theme) => ({
        borderTopLeftRadius: hoverStartDay.hasSame(day, 'day') ? undefined : 0,
        borderBottomLeftRadius: hoverStartDay.hasSame(day, 'day') ? undefined : 0,
        borderTopRightRadius: hoverEndDay.hasSame(day, 'day') ? undefined : 0,
        borderBottomRightRadius: hoverEndDay.hasSame(day, 'day') ? undefined : 0,
        // width: 40,
        // width: 40,
        margin: 0,
        backgroundColor: hoverColor,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
          backgroundColor: hoverColor,
        },
        '&.Mui-selected': {
          backgroundColor: hoverColor,
        }
      });
    }

    newProps.onMouseEnter = () => onHoverDate(day);
    newProps.onMouseLeave = () => onHoverDate(undefined);
  } else if (rangeType === 'day') {
    newProps.disableMargin = true;
    newProps.sx = (theme) => ({
      // width: 40,
      // width: 40,
      '&:hover, &:focus': {
        backgroundColor: hoverColor,
        color: theme.palette.common.white,
      },
      '&.MuiPickersDay-root.Mui-selected': {
        backgroundColor: highlightedColor,
        '&:hover': {
          backgroundColor: highlightedColor,
        }
      },
    });
  }

  return <PickersDay {...newProps} />;
};
