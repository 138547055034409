import { ReactElement, useMemo } from 'react';
import { useCabinetText } from '../../../CabinetContext';
import { EVENT_TYPE, PAGE_URL, TIER, NEW_TIER } from '../../../constants';
import { trackEventWithExtra } from '../../../utils/appAnalyticsUtils';
import CabinetPromo from './CabinetPromo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useNavigate } from 'react-router-dom';

interface ComponentProps {
  onClose: () => void;
}

type Props = ComponentProps;

const CabinetPromoContainer = ({ onClose }: Props): ReactElement => {
  const user = useSelector((state: RootState) => state.auth.user);

  const navigate = useNavigate();

  const userIsBasic = (user?.active_license.tier === NEW_TIER.BASIC);
  
  const [starterDetails, largerTeamDetails, individualDetails] = useCabinetText(
    ['promo-starter-features', 'promo-larger-team-features', 'onboard-individual-features']);

  const planOptions = useMemo(() => {
    const plans = [];
    if (userIsBasic) {
      plans.push({
        tierKey: NEW_TIER.INDIVIDUAL,
        name: 'Individual',
        monthlyPrice: 39,
        annualPrice: 372,
        buttonText: 'Try for Free',
        features: individualDetails,
      });
    }

    plans.push(
      {
        tierKey: NEW_TIER.STARTER,
        name: 'Starter',
        monthlyPrice: 49,
        annualPrice: 516,
        buttonText: 'Try for Free',
        features: starterDetails,
      },
      {
        tierKey: NEW_TIER.GROWTH,
        name: 'Growth/Premier',
        monthlyPrice: 99,
        annualPrice: 1118,
        buttonText: 'Learn More',
        features: largerTeamDetails,
        monthlyPriceString: '$99-229/month',
      },
    );
    return plans;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [starterDetails.join(','), largerTeamDetails.join(',')]);

  const handlePlanSelected = (tier: TIER) => {
    trackEventWithExtra(
      {eventName: EVENT_TYPE.PROMO_TIER_CLICKED, extra: {tier}}, 
      () => {
        navigate(PAGE_URL.MY_TEAM);
        onClose();
      }
    );
  };

  return <CabinetPromo planOptions={planOptions} onPlanSelected={handlePlanSelected} userIsBasic={userIsBasic} />;
};

export default CabinetPromoContainer;