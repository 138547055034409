import { DateTime } from 'luxon'; 


export const formatSlot = (startDate: Date, endDate: Date, tz: string): string => {
  const startDateFormatted = DateTime.fromJSDate(startDate, { zone: tz }).toFormat('h:mma').toLowerCase();
  const endDateFormatted = DateTime.fromJSDate(endDate, { zone: tz }).toFormat('h:mma').toLowerCase();
  return startDateFormatted + ' - ' + endDateFormatted;
};

export const formatSlotTimeTiny = (startDate: Date, endDate: Date, tz: string): string => {
  const start = DateTime.fromJSDate(startDate, { zone: tz });
  const end = DateTime.fromJSDate(endDate, { zone: tz });
  return `${start.toFormat('h:mma').toLowerCase()} - ${end.toFormat('h:mma').toLowerCase()}`;
};

export const timeRegex = /^(?<h>(?:[0-9]|1[0-2])?):? ?(?<m>[0-9][0-9])? ?(?<a>[ap])?m?$/;

export const addLeadingZero = (val: string) => (val.length < 2 ? `0${val}` : `${val}`);

export const convert24hrTo12hr = (time: string): string => {
  const [hours, mins] = time.split(":").map(Number);
  const ampm = hours > 11 && hours !== 24 ? "pm" : "am";
  const newHours = hours === 24 ? 12 : hours % 12;
  const hoursStr = newHours.toString();
  const minsStr = addLeadingZero(mins.toString());
  const newTime = `${hoursStr}:${minsStr} ${ampm}`;
  return newTime;
};

export const convert12hrTo24hr = (time: string): string => {
  const match = timeRegex.exec(time);
  if (!match || !match.groups || !match.groups.h || !match.groups.m || !match.groups.a) {
    throw Error("Invalid 12-hour Time Format: Should be hh:mm aa");
  }
  const hours = match.groups.h;
  const mins = match.groups.m;
  const ampm = match.groups.a === "a" ? "am" : "pm";

  const offsetHours = (ampm === "pm" && hours !== "12") || (ampm === "am" && hours === "12") 
    ? `${(Number(hours) + 12) % 24}` 
    : hours;
  const hoursStr = addLeadingZero(offsetHours);
  const minsStr = addLeadingZero(mins);
  const newTime = `${hoursStr}:${minsStr}`;
  return newTime;
};

// export const getStartOfWeek = (date: DateTime, weekStartDay: number): DateTime => {
//   workWeekDayOffset = useMemo(() => (
//     weekStartDay === 1 ? 0 : 1
//   ), [weekStartDay]);
// };